import React from 'react'
import LoadinSeccion from '../../general/LoadinSeccion'
import im from '../../../assets/ic_sm_gris_2.png'
import PropTypes from 'prop-types'

const ChatsReportes = ({chats, reportes, cargando, seleccionar, esChat}) => {
    let color=false
    let k=0
    let listaObjetos = esChat ? chats : reportes
    return (
        <div className="clubsContainer">
            <div className="vistaClubs fondo-blanco">
                <div className="listaClubs">
                    {cargando?
                        <LoadinSeccion/>
                    :
                        listaObjetos===null ?
                        <div className="chat fondo-azul">
                            <div className="chatImagenContainer">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Escudo_de_Atl%C3%A9tico_Nacional.png"  alt=""/>
                            </div>
                            <div className="textosChat">
                                <div><b></b></div>
                                <div>Actualmente no hay {esChat ? "chats" : "reportes"}</div>
                            </div>
                            <div className="fechaMensaje"></div>
                        </div>
                        :
                        listaObjetos.length > 0 ? listaObjetos.map((objeto)=>{
                            let fechaObjeto = esChat ? objeto.fechaUltimoMensaje : objeto.fechaUltimoReporte
                            let fotoObjeto = esChat ? objeto.urlFotoPerfil : objeto.urlFotoReportado
                            let nombreObjeto = esChat ? objeto.nombre : objeto.nombreReportado
                            let ultimoMensaje = objeto.ultimoMensaje

                            let startTime = fechaObjeto.toDate(); 
                            let endTime = new Date();
                            let difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
                            let resultInMinutes = Math.round(difference / 60000);
                            let t
                            if(resultInMinutes<60){
                                t = `Hace ${Math.round(resultInMinutes)} Minutos`
                            }
                            else if((resultInMinutes/60)<24){
                                t = `Hace ${Math.round(resultInMinutes/60)} Horas`
                            }
                            else if(((resultInMinutes/60))/24<30){
                                t = `Hace ${Math.round(resultInMinutes/60)} Días`
                            }
                            else if(((resultInMinutes/60))/24>30){
                                t = `${startTime.getDay()}/${startTime.getMonth()}/${startTime.getFullYear()}`
                            }

                            color=!color
                            k+=1
                            return (
                                <div className={`chat ${color?'fondo-azul':'fondo-gris'}`} onClick={()=>seleccionar(objeto)} key={`chat${k}`}>
                                    <div className="chatImagenContainer">
                                        <img src={fotoObjeto}  alt=""/>
                                    </div>
                                    <div className="textosChat">
                                        <div><b>{nombreObjeto +':'}</b></div>
                                        <div>{ultimoMensaje}</div>
                                    </div>
                                    <div className="fechaMensaje">{t}</div>
                                </div>
                            )
                        })
                        :<div className="chat fondo-azul">
                            <div className="chatImagenContainer">
                                <img src={im}  alt=""/>
                            </div>
                            <div className="textosChat">
                                <div><b></b></div>
                                <div>Actualmente no hay {esChat ? "chats" : "reportes"}</div>
                            </div>
                            <div className="fechaMensaje"></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

ChatsReportes.propTypes = {
    reportes: PropTypes.array,
    chats: PropTypes.array,
    esChat: PropTypes.bool,
    cargando: PropTypes.bool.isRequired,
    seleccionar: PropTypes.func.isRequired
}

export default ChatsReportes
