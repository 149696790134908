import React, { useEffect } from 'react';
import '../../styles/politicas.scss'
import sm from '../../assets/ic_sm_horizontal.png'
import { Link } from 'react-router-dom'

const TerminosCondiciones = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="fondoPoliticas">
            <div className="topImagen">
                <Link to="/" className="urlImagen"><img src={sm} alt="logo" /></Link>
            </div>
            <div className="topPoliticas">TÉRMINOS Y CONDICIONES</div>
            <div className='archivo'>
                <div className='contenedor-archivo'>
                    <>
                        <h1>TÉRMINOS Y CONDICIONES SOCCER MARKETING</h1>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            Bienvenido a <strong>SOCCER MARKETING</strong>, a La Sociedad{" "}
                            <strong>SB DEVELOPERS S.A.S., </strong>propietaria y legítima titular de
                            todos los derechos derivados de la plataforma y la marca{" "}
                            <strong>SOCCER MARKETING</strong>, quien en adelante se denominará La
                            Sociedad, le complace ofrecerle acceso a la plataforma, la cual prestará los
                            servicios que se describirán en el presente documento. Estando sujeto a los
                            siguientes Términos y Condiciones se entiende que usted ha expresado su
                            consentimiento, voluntario, exento de vicios, previo e informado de los
                            Términos y Condiciones al momento de registrarse, acceder, aceptar estos
                            Términos y Condiciones y utilizar la plataforma. Si no está de acuerdo con
                            los Términos y Condiciones que aquí se describen, le sugerimos no hacer
                            click en acepto Términos y Condiciones y no hacer uso de la plataforma.
                        </p>
                        <p>
                            Por favor lea todos los términos y condiciones que aquí se incluyen, ya que
                            La Sociedad se exime de cualquier responsabilidad derivada del
                            desconocimiento del contenido del presente documento.
                        </p>
                        <p>&nbsp;</p>
                        <h2>I. GENERALIDADES DEL SERVICIO</h2>
                        <p>
                            A continuación, describiremos los servicios proporcionados por La Sociedad a
                            través de la presente plataforma digital.
                        </p>
                        <p>
                            La Sociedad es titular de todos los derechos patrimoniales sobre la
                            plataforma denominada <strong>SOCCER MARKETING </strong>la cual tiene como
                            objetivo conectar a jugadores, técnicos y agentes de fútbol con clubes
                            deportivos de fútbol, facilitándoles a los primeros la visibilidad a nivel
                            mundial en el mercado objetivo y a los segundos el reclutamiento de los
                            jugadores y/o técnicos que cumplan con las características requeridas por lo
                            clubes.
                        </p>
                        <p>Se entenderán por Usuarios, las siguientes personas:</p>
                        <ul>
                            <li>
                                Las personas naturales entre los 12 y 17 años, siempre que sus padres y/o
                                representantes legales hayan registrado y/o autorizado a sus hijos hacer
                                parte de <strong>SOCCER MARKETING</strong>.
                            </li>
                            <li>
                                Las personas naturales que quieran debutar profesionalmente y se
                                encuentren entre los 18 y 25 años.
                            </li>
                            <li>
                                Las personas naturales que se encuentren entre los 13 y 40 años que tienen
                                una carrera deportiva profesional.
                            </li>
                            <li>
                                Las personas jurídicas y/o naturales que actúen en calidad de agentes
                            </li>
                            <li>Las personas naturales que tengan calidad de técnicos de fútbol.</li>
                            <li>Clubes y/o asociaciones de fútbol.</li>
                        </ul>
                        <p>
                            El usuario deberá registrarse mediante su correo electrónico, Apple ID o por
                            Facebook para poder tener ingreso a la plataforma.
                        </p>
                        <p>
                            El usuario deberá diligenciar el formulario con la información que se
                            requiere, garantizando su veracidad. Por lo anterior, La Sociedad, no es
                            responsable sobre la veracidad o exactitud de la información que el usuario
                            suministre a la plataforma.
                        </p>
                        <p>
                            En caso de que el jugador sea menor de edad, dependiendo de la jurisdicción
                            a la que le corresponda, los padres y/o representante legal deberán
                            diligenciar el formulario con la información personal del menor y la que se
                            requiera de los padres y/o representantes. Posteriormente, La Sociedad
                            notificará mediante correo electrónico, a los padres y/o representante
                            legal, el registro del menor en la plataforma y cualquier contacto que este
                            reciba por parte de un club.
                        </p>
                        <p>
                            Lo anterior exime de responsabilidad a La Sociedad si los padres del menor
                            de edad no vigilan y custodian el uso de la plataforma por parte de sus
                            hijos, se hace pasar por sus padres y/o representante y acepta estos
                            términos y condiciones.
                        </p>
                        <p>
                            Se le permitirá al usuario subir y/o suministrar los siguientes documentos
                            y/o información:
                        </p>
                        <ul>
                            <li>Formulario datos personales</li>
                            <li>Videos de los jugadores</li>
                            <li>Récord médico</li>
                            <li>
                                Los datos correspondientes a los partidos jugados y/o goles anotados.
                            </li>
                            <li>Otros datos correspondientes.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <h2>II. CONSIDERACIONES GENERALES</h2>
                        <p>
                            Al aceptar estos Términos y Condiciones, se le concede a usted un derecho
                            limitado, no exclusivo, intransferible y no sub-licenciable, para entrar,
                            acceder y utilizar la plataforma conforme a lo establecido en el plan que
                            haya sido escogido por usted. Esto con el fin de que los Usuarios tengan un
                            mecanismo idóneo y expedito para poder hacerse visibles alrededor del mundo.
                            Lo anterior no se limita solamente a jugadores profesionales si no a
                            jugadores amateurs que quieran vincularse a la plataforma con fines
                            profesionales y/o personales.
                        </p>
                        <p>
                            En ese sentido, todos los derechos que <strong>no </strong>se le conceden
                            expresamente a usted en estos Términos y Condiciones, están reservados para
                            La Sociedad y sus licenciatarios,
                        </p>
                        <p>
                            según corresponda. Es por esto que usted acepta que este permiso es para su
                            uso personal, razón por la cual se considerará vulnerado el derecho cuando
                            personas ajenas a usted hagan uso de las facultades que la plataforma les
                            otorga.
                        </p>
                        <p>
                            Así mismo, usted no tiene derecho para copiar o reproducir parcial o
                            totalmente el contenido y funcionamiento de la plataforma, ni los videos y
                            contenido multimedia que haya sido subido por cualquiera de los otros
                            usuarios de la plataforma, ya que se encuentran limitados por lo establecido
                            en las leyes y tratados internacionales, correspondientes al derecho de
                            autor, propiedad intelectual y cualquier otra regulación que sea necesaria
                            para la aplicación de los presentes Términos y Condiciones, como se
                            desarrollará más adelante.
                        </p>
                        <p>&nbsp;</p>
                        <h3>
                            USO, MANEJO Y ADMINISTRACIÓN DE DATOS<strong>&nbsp;</strong>
                        </h3>
                        <p>
                            Como consecuencia del acceso y utilización de la plataforma, el usuario
                            autoriza expresamente a La Sociedad de hacer uso de su información personal,
                            tales como correo electrónico, cuenta bancaria, número de identificación
                            (cédula de ciudadanía, extranjería, número de pasaporte, tarjeta de
                            identidad), nombre, apellido, hoja de vida identificando su trayectoria
                            profesional, con el fin de que los clubes vinculados a la plataforma puedan
                            tener de primera mano la información más importante para tomar la decisión
                            que más se adecúe a su expectativas.
                        </p>
                        <p>
                            Lo anterior con el propósito de que La Sociedad pueda garantizar el correcto
                            manejo a la información personal de los menores de edad, y a su vez entregar
                            la información completa a los clubes y servir como entre los jugadores,
                            agentes, técnicos y los clubes y/o asociaciones a las que haya lugar.
                        </p>
                        <p>
                            En el mismo sentido, el usuario autoriza a{" "}
                            <strong>SOCCER MARKETING </strong>a:
                        </p>
                        <ul>
                            <li>
                                Actualizar, modificar e instalar el software, de manera automática o
                                requerida, entre otras razones para abordar cuestiones de seguridad,
                                interoperabilidad y/o Estas actualizaciones, modificaciones y operaciones
                                similares pueden realizarse en forma periódica o cuando sea necesario, sin
                                previo aviso.
                            </li>
                            <li>
                                Crear un protocolo de notificación que se enviará al correo de los padres
                                y/o los representantes legales del menor de edad, para informar las
                                siguientes circunstancias:
                                <ul>
                                    <li>El registro a la plataforma</li>
                                    <li>
                                        Los mensajes enviados por los clubes y/o asociaciones al menor de edad
                                    </li>
                                    <li>Una copia de los Términos y Condiciones</li>
                                </ul>
                            </li>
                            <li>
                                Vender, sublicenciar y publicar los datos personales siempre que se
                                encuentren en relación directa y/o indirecta con los servicios
                                proporcionados por La Sociedad
                            </li>
                        </ul>
                        <p>
                            La Sociedad se ceñirá a lo establecido en la Ley 1581 de 2012 y el Decreto
                            1377 de 2013 de la legislación colombiana, para dar el adecuado tratamiento
                            de los datos personales, tal y como se muestra a continuación:
                        </p>
                        <p>&nbsp;</p>
                        <h3>Obligaciones de los responsables del tratamiento de datos</h3>
                        <ul>
                            <li>
                                Garantizar al usuario el uso efectivo, plena y en todo tiempo de los
                                derechos de hábeas data;
                            </li>
                            <li>
                                Impedir la adulteración de la información, pérdida, consulta, uso o acceso
                                no autorizado o fraudulento;
                            </li>
                            <li>
                                Respetar las condiciones de seguridad y privacidad de la información del
                                usuario;
                            </li>
                            <li>
                                Informar a la autoridad de protección de datos, Superintendencia de
                                Industria y Comercio (SIC), cuando se presenten violaciones a los códigos
                                de seguridad y existan riesgos en la administración de la información de
                                los usuarios.
                            </li>
                            <li>
                                Cumplir las instrucciones y requerimientos que imparta la Superintendencia
                                de Industria y Comercio.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3>Obligaciones de los encargados del tratamiento de datos</h3>
                        <ul>
                            <li>
                                Garantizar al usuario el uso efectivo, plena y en todo tiempo de los
                                derechos de hábeas data;
                            </li>
                            <li>
                                Impedir la adulteración de la información, pérdida, consulta, uso o acceso
                                no autorizado o fraudulento;
                            </li>
                            <li>
                                Abstenerse de circular información que esté siendo controvertida por el
                                usuario y cuyo bloqueo haya sido ordenado por la Superintendencia de
                                Industria y Comercio;
                            </li>
                            <li>
                                Permitir el acceso a la información únicamente a las personas que pueden
                                tener acceso a ella;
                            </li>
                            <li>
                                Informar a la Superintendencia de Industria y Comercio cuando se presenten
                                violaciones a los códigos de seguridad y existan riesgos en la
                                administración de la información de los Titulares;
                            </li>
                            <li>
                                Cumplir las instrucciones y requerimientos que imparta la Superintendencia
                                de Industria y Comercio.
                            </li>
                        </ul>
                        <p>
                            En ese sentido<strong>, </strong>La Sociedad se exime de cualquier
                            responsabilidad derivada del incumplimiento de alguna de sus obligaciones
                            tendientes a la protección y tratamiento de datos, siempre que no exista
                            plena prueba que demuestre el daño ocasionado al usuario o que este último
                            haya ocasionado su propio perjuicio de manera dolosa o culposa.
                        </p>
                        <p>&nbsp;</p>
                        <h3>SEGURIDAD DE LA INFORMACIÓN</h3>
                        <p>
                            La Sociedad se asegurará de actuar diligentemente para evitar e impedir
                            hurto, fraude, filtraciones, uso inadecuado de la información. Para lo
                            anterior, los trabajadores, representante legal principal y/o suplente,
                            miembros de la Junta Directiva, proveedores o cualquier otra persona que
                            tenga un vínculo contractual con esta<strong>, </strong>deberán cumplir con
                            las políticas, normas, procedimientos y buenas prácticas de seguridad de la
                            información establecidas por La Sociedad.
                        </p>
                        <p>
                            Adicionalmente, se garantizará un respaldo de información cada veinticuatro
                            (24) horas, la cual estará disponible para los usuarios cuando estos lo
                            requieran.
                        </p>
                        <p>&nbsp;</p>
                        <h3>
                            REQUISITO DE EDAD<strong>&nbsp;</strong>
                        </h3>
                        <p>
                            De acuerdo con la Ley 1581 de 2012 en el art. 2.2.8.4.2.4. de la legislación
                            colombiana, se ha establecido la prohibición del tratamiento de los datos
                            personales de menores de 18 años o los menores de edad de acuerdo con la
                            jurisdicción en la que se encuentre el usuario, sin previa autorización de
                            su padre, madre o cualquier persona que tenga la custodia del menor y que
                            actúe como su representante legal. En ese sentido, si usted es menor de 18
                            años o menor de edad, y pretende acceder a la plataforma, De manera
                            obligatoria, e imprescindible su padre, madre, tutor o representante legal
                            deben aceptar estos Términos y Condiciones y suministrar la información que
                            se solicitan en el formulario.
                        </p>
                        <p>
                            De igual forma, Cuando se trate de menor de 14 años, por ser incapaces
                            absolutos en la legislación colombiana, o el equivalente en la jurisdicción
                            en la que se encuentre el usuario, no deberá proporcionar información alguna
                            a La Sociedad ni a las asociaciones y/o clubes sin que sus padres, tutor y/o
                            representante legal lo permita, ya sea mediante el uso de la plataforma o a
                            través de cualquier otra actividad con ocasión del desarrollo de la
                            plataforma.
                        </p>
                        <p>&nbsp;</p>
                        <h3>
                            CONDUCTAS PROHIBIDAS<strong>&nbsp;</strong>
                        </h3>
                        <p>
                            Se considera una conducta grave y se encuentra completamente prohibido para
                            los proveedores, administradores y usuarios realizar las siguientes
                            actividades:
                        </p>
                        <ul>
                            <li>
                                Alojar en su propio sitio imágenes, textos, documentos o archivos
                                audiovisuales que impliquen directa o indirectamente actividades sexuales
                                con menores de
                            </li>
                            <li>
                                Alojar en su propio sitio material pornográfico, en especial en modo de
                                imágenes o videos, cuando existan indicios de que las personas
                                fotografiadas o filmadas son menores de edad.
                            </li>
                            <li>
                                Alojar en su propio sitio vínculos o links, sobre sitios telemáticos que
                                contengan o distribuyan material pornográfico relativo a menores de edad.
                            </li>
                            <li>
                                Alojar datos personales de los menores de catorce (14) años o los menores
                                de edad de acuerdo con la jurisdicción en la que se encuentre el usuario,
                                sin previa autorización de sus padres, tutor y/o representante legal.
                            </li>
                            <li>
                                Alojar los datos personales de los menores de dieciocho (18) años o los
                                menores de edad de acuerdo con la jurisdicción en la que se encuentre el
                                usuario, sin previa autorización de su papá, mamá o quien tenga la
                                custodia del menor.
                            </li>
                            <li>
                                Transmitir, divulgar, transformar o poner a disposición pública cualquier
                                contenido o material que viole o infrinja derechos de autor, marcas
                                registradas, patentes, secretos comerciales y otros derechos de propiedad
                                intelectual, derechos de privacidad o publicidad, reglamentos o estatutos
                                de comunicaciones, o cualesquiera otras leyes, incluyendo, sin limitación,
                                las leyes sobre difamación, acoso, obscenidad y pornografía.
                            </li>
                            <li>
                                Divulgar campañas políticas, contenido con virus informáticos u otro
                                código de computadora destinado a interferir con la funcionalidad de los
                                sistemas de
                            </li>
                            <li>
                                Interrumpir ni intentar interrumpir la operación de la plataforma de
                                ninguna manera o hacer uso de una dirección de correo electrónico falso
                                con la finalidad de engañar a otros miembros en cuanto a su identidad o al
                                origen de un mensaje o
                            </li>
                        </ul>
                        <p>
                            Es obligación del Usuario hacer un uso adecuado y respetuoso de la
                            plataforma. Cualquier violación a lo aquí dispuesto, estará sujeta a la
                            revisión de La Sociedad para adoptar las acciones legales correspondientes.
                        </p>
                        <p>&nbsp;</p>
                        <h3>Restricción sobre el uso de la plataforma y su contenido</h3>
                        <p>
                            La plataforma incluye componentes de seguridad por lo que se aplican normas
                            y políticas especiales, que determinan que usted no deberá intentar eludir,
                            aplicar ingeniería inversa, descifrar, descompilar, desmontar o de algún
                            modo modificar, alterar o interferir con ningún aspecto de la plataforma. En
                            el mismo sentido, usted no podrá distribuir, intercambiar, modificar, vender
                            o revender, o transmitir a cualquier otra persona cualquier parte de la
                            plataforma, incluyendo, sin limitación, cualquier texto, imagen, audio o
                            video para cualquier propósito empresarial, comercial o público. Usted
                            conviene en no copiar, vender, distribuir o de algún modo transferir el
                            contenido de la plataforma <strong>SOCCER MARKETING </strong>salvo que
                            exista una licencia que así se lo permita.
                        </p>
                        <p>&nbsp;</p>
                        <h3>Restricción sobre los contenidos de SOCCER MARKETING</h3>
                        <p>El usuario NO podrá ejercer los siguientes derechos:</p>
                        <ul>
                            <li>
                                Derechos de reproducción o uso promocional en el contenido de{" "}
                                <strong>SOCCER MARKETING</strong>.
                            </li>
                            <li>
                                Derechos para celebrar ningún contrato siendo intermediario del club y/o
                                asociación.
                            </li>
                            <li>
                                Derechos de distribuir o redistribuir el contenido de{" "}
                                <strong>SOCCER MARKETING </strong>en aplicaciones de streaming (vía
                                Internet, intranets y/u otras redes),
                            </li>
                            <li>
                                Derechos de distribuir a otras personas el contenido de{" "}
                                <strong>SOCCER MARKETING </strong>otros sistemas de distribución de
                                contenido o en medios físicos (discos compactos, discos digitales
                                versátiles, chips semiconductores, discos duros, tarjetas de memoria y
                                similares)
                            </li>
                            <li>
                                Derechos de uso comercial, venta, reventa, reproducción, distribución o
                                promocional del contenido de <strong>SOCCER MARKETING</strong>
                            </li>
                        </ul>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <h3>Restricción sobre los contenidos de los Usuarios</h3>
                        <p>
                            Ni los usuarios, ni cualquier otra persona, podrá hacer uso de los videos
                            que hayan sido subidos a la plataforma. No podrán ser comercializados,
                            vendidos, licenciados, sublicenciados, prestados, arrendados ni cualquier
                            otra modalidad de transferencia de la propiedad.
                        </p>
                        <p>
                            En caso de que esto suceda, el usuario podrá hacer valer su derecho
                            ejerciendo las acciones correspondientes a la infracción del derecho de
                            autor, uso de imagen personal, responsabilidad extracontractual y/o
                            cualquier acción legal a la que haya lugar.
                        </p>
                        <p>
                            Para no infringir las anteriores restricciones, vale la pena tener en cuenta
                            que cualquier solución de gestión de derechos digitales que se proporciona
                            con el contenido de <strong>SOCCER MARKETING </strong>es una parte
                            inseparable del mismo, y no podrá eludirse, salvo en la medida permitida por
                            la ley aplicable. Si usted está en posesión, control y/o uso del contenido
                            de <strong>SOCCER MARKETING</strong>, es su responsabilidad no perder,
                            destruir ni dañar dicho contenido, comercializar, reproducir o cualquier
                            otra actuación que se asemeje, sin previa autorización de La Sociedad.
                        </p>
                        <p>&nbsp;</p>
                        <h3>INFORMACIÓN AL CONSUMIDOR</h3>
                        <p>
                            Se deja constancia que La Sociedad suministró a los usuarios la información
                            en castellano, clara, veraz, suficiente, oportuna, verificable,
                            comprensible, precisa e idónea sobre los servicios prestados. Así mismo,
                            suministró la siguiente información mediante estos términos y condiciones:
                        </p>
                        <ul>
                            <li>Las instrucciones para el correcto uso de la plataforma</li>
                            <li>
                                Las especificaciones de los servicios prestados a través de la plataforma
                            </li>
                            <li>Los valores correspondientes a los planes de vinculación</li>
                        </ul>
                        <p>&nbsp;</p>
                        <h2>III. CONDICIONES DE USO Y ACCESO PROTOCOLO DE USUARIO</h2>
                        <ul>
                            <li>
                                El usuario debe registrarse por medio de su correo electrónico, su Apple
                                ID y/o Facebook para poder acceder a la plataforma.
                            </li>
                            <li>
                                Una vez se haya realizado el registro, el usuario deberá diligenciar un
                                formulario con sus datos personales tales como: nombre(s), apellidos,
                                correo electrónico, país de residencia, ciudad de residencia, número
                                telefónico, número de celular y cualquier otro dato necesario para validar
                                la identidad de la persona.
                            </li>
                            <li>
                                Tan pronto se haya diligenciado el formato de validación de datos, el
                                usuario deberá escoger el tipo de plan con el cual se va a afiliar a la
                                plataforma, ya que de esto dependerá el alcance del uso de la Los planes
                                serán descritos en el contenido del presente documento.
                            </li>
                            <li>
                                Posteriormente, será necesario que el usuario diligencie su perfil con la
                                información correspondiente, completando el treinta por ciento (30%) de su
                                En caso de ser un menor de edad, el tutor, madre, padre o representante
                                legal del menor deberá leer y aceptar los términos y condiciones
                                correspondientes a los Usuarios menores de edad, adicionalmente será
                                necesario que los el padre, madre, tutor y/o representante legal del menor
                                registre su nombre, apellido, documento de identidad y dos correos
                                electrónicos, en los cuales La Sociedad notificará todos los eventos
                                relacionados con la plataforma y comunicaciones de los clubes y/o
                                asociaciones.
                            </li>
                            <li>
                                Una vez diligenciada la anterior información, el usuario tendrá la
                                posibilidad de subir videos, el récord médico y la información
                                correspondiente a los goles anotados y los partidos jugados. Lo anterior
                                con la finalidad de poder llevar un recuento de la información que puede
                                llegar a ser requerida por los clubes y/o asociaciones, ya que es muy
                                posible que los usuarios no lleven un registro de esta información.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3>PLANES DE VINCULACIÓN</h3>
                        <ul>
                            <li>
                                <strong>Plan Gratis: </strong>El usuario podrá crear un perfil, generar su
                                hoja de vida y compartirla durante un periodo de prueba por 3 meses sin
                                ningún costo.
                            </li>
                            <li>
                                <strong>Plan HV: </strong>El usuario podrá crear un perfil, generar su
                                hoja de vida y compartirla.
                            </li>
                            <li>
                                <strong>Plan Basic: </strong>El usuario podrá crear un perfil en la
                                plataforma, generar su hoja de vida y Será visible para los clubes de 1
                                país, y tendrá contacto directo desde la plataforma con los clubes.
                            </li>
                            <li>
                                <strong>Plan Advanced: </strong>El usuario podrá crear un perfil en la
                                plataforma, generar su hoja de vida y compartirla. Será visible para los
                                clubes de 5 países a elección del usuario, dentro de los existentes en el
                                catálogo de la plataforma, y tendrá contacto directo desde la plataforma
                                con los clubes.
                            </li>
                            <li>
                                <strong>Plan Premium: </strong>El usuario podrá crear un perfil en la
                                plataforma y recibirá recomendaciones que posicionen su perfil, generar su
                                hoja de vida y compartirla. Será visible para todos los clubes del mundo y
                                su perfil será posicionado y destacado de acuerdo con los criterios de
                                búsqueda de los clubes.
                            </li>
                        </ul>
                    </>

                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <br />
                                </td>
                                <td style={{ width: "14.3593%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <strong>
                                            <span style={{ fontSize: 15, fontFamily: '"Arial",sans-serif' }}>
                                                Plan gratis
                                            </span>
                                        </strong>
                                    </div>
                                </td>
                                <td style={{ width: "16.6421%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <strong>
                                            <span style={{ fontSize: 15, fontFamily: '"Arial",sans-serif' }}>
                                                Plan HV
                                            </span>
                                        </strong>
                                    </div>
                                </td>
                                <td style={{ width: "16.6421%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <strong>
                                            <span style={{ fontSize: 15, fontFamily: '"Arial",sans-serif' }}>
                                                Plan Basic
                                            </span>
                                        </strong>
                                    </div>
                                </td>
                                <td style={{ width: "16.6667%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <strong>
                                            <span style={{ fontSize: 15, fontFamily: '"Arial",sans-serif' }}>
                                                Plan Advanced
                                            </span>
                                        </strong>
                                    </div>
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>
                                    <strong>
                                        <span style={{ fontSize: 15, fontFamily: '"Arial",sans-serif' }}>
                                            Plan Premium
                                        </span>
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <strong>
                                        <span style={{ fontSize: 13, fontFamily: '"Arial",sans-serif' }}>
                                            Creación de Perfil
                                        </span>
                                    </strong>
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <strong>
                                        <span style={{ fontSize: 13, fontFamily: '"Arial",sans-serif' }}>
                                            Compartir hoja de vida
                                        </span>
                                    </strong>
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <strong>
                                        <span style={{ fontSize: 13, fontFamily: '"Arial",sans-serif' }}>
                                            Contacto con clubes
                                        </span>
                                    </strong>
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <strong>
                                        <span style={{ fontSize: 13, fontFamily: '"Arial",sans-serif' }}>
                                            Visibilidad
                                        </span>
                                    </strong>
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    Clubes del país de residencia
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>
                                    Clubes de 5 países
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>
                                    Clubes de todo el mundo
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <span style={{ fontSize: 13, fontFamily: '"Arial",sans-serif' }}>
                                        <strong>Perfil destacado</strong>
                                    </span>
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <span style={{ fontSize: 13, fontFamily: '"Arial",sans-serif' }}>
                                        <strong>Sugerencias y recomendaciones de perfil</strong>
                                    </span>
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>
                                    <br />
                                </td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>X</td>
                            </tr>
                            <tr>
                                <td style={{ width: "19.0722%" }}>
                                    <br />
                                </td>
                                <td style={{ width: "14.3593%", textAlign: "center" }}>3 meses</td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>$0,99/Mes</td>
                                <td style={{ width: "16.6421%", textAlign: "center" }}>$4,99/Mes</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>$9,99/Mes</td>
                                <td style={{ width: "16.6667%", textAlign: "center" }}>$19,99/Mes</td>
                            </tr>
                        </tbody>
                    </table>
                    <ul>
                        <li>
                            La Sociedad se reserva el derecho de modificar, rescindir o rectificar los
                            planes de suscripción aquí descritos en cualquier momento de conformidad con
                            estos términos.
                        </li>
                        <li>
                            La sociedad se compromete a desplegar toda la diligencia posible para dar la
                            visibilidad del Usuario de acuerdo con el plan seleccionado, sin
                            comprometerse a algún resultado en específico.
                        </li>
                    </ul>
                    <h3>FORMA DE PAGO</h3>
                    <p>
                        El usuario podrá adquirir la suscripción al plan seleccionado de manera
                        electrónica y directamente desde la Plataforma{" "}
                        <strong>SOCCER MARKETING</strong>, pagando la tarifa de suscripción en
                        dólares, por adelantado y mensualmente<strong>. </strong>Se utilizarán todos
                        los medios de pago soportados por las pasarelas de pago utilizadas por la
                        plataforma, las cuales son:
                    </p>
                    <ul>
                        <li>Google Play Billing, para los usuarios Android,</li>
                        <li>Apple Pay para los usuarios de IOS.</li>
                    </ul>
                    <p>
                        Las políticas de pago serán aplicadas de acuerdo con Los Términos y
                        Condiciones de las pasarelas de pago utilizadas por la plataforma.
                    </p>
                    <p>&nbsp;</p>
                    <h3>SUSCRIPCIÓN AUTO RENOVABLE SOCCER MARKETING</h3>
                    <p>
                        Su suscripción se cargará a su cuenta y se renovará de{" "}
                        <strong>manera automática mensualmente </strong>a menos que deshabilite la
                        renovación automática al menos 24 horas antes del final del período vigente,
                        o no sea posible debitar el valor de la suscripción mensual.
                    </p>
                    <p>
                        A partir de la fecha de suscripción en cualquiera de los planes de
                        vinculación, usted podrá hacer uso de la plataforma durante los treinta (30)
                        días siguientes y hasta la fecha de corte del mes siguiente.
                    </p>
                    <ul>
                        <li>
                            Todas las suscripciones tienen una duración de un (1) mes y su costo
                            dependerá del plan elegido por el usuario.
                        </li>
                        <li>
                            La suscripción no se podrá cancelar durante el período de vigencia activa;
                            sin embargo, puede administrar su suscripción y/o desactivar la renovación
                            automática visitando la configuración de su cuenta después de la compra.
                        </li>
                    </ul>
                    <p>
                        Puede consultar nuestra Política de Privacidad y Condiciones de Uso:{" "}
                        <u>https://soccermarketing.co/</u>
                    </p>
                    <p>&nbsp;</p>
                    <h3>CANCELACIÓN PERMANENTE DE LA CUENTA DEL USUARIO</h3>
                    <p>
                        La Sociedad se reserva el derecho de cancelar de manera permanente la cuenta
                        del Usuario, si se presentase alguna de las siguientes circunstancias y sin
                        limitarse a las mismas:
                    </p>
                    <ul>
                        <li>
                            Por el incumplimiento de alguna de las obligaciones y/o restricciones
                            incluidas en el presente documento
                        </li>
                        <li>
                            Por el uso, sin autorización, de algún signo distintivo de titularidad de
                            La
                        </li>
                        <li>Por el uso sin autorización de los videos de los otros usuarios.</li>
                        <li>
                            Por la infracción de los derechos de autor y conexos de La Sociedad y de
                            los
                        </li>
                        <li>
                            Por hacer uso de la plataforma para ejecutar actos ilegales a la luz del
                            ordenamiento jurídico colombiano.
                        </li>
                        <li>
                            Por inexactitud o falta de veracidad en la información suministrada a la
                            plataforma
                        </li>
                        <li>
                            Por ejecutar cualquiera de las conductas prohibidas referidas en el
                            presente
                        </li>
                        <li>
                            Por el uso inadecuado o irresponsable de la plataforma en los términos
                            aquí
                        </li>
                        <li>
                            Por ser menor de edad y no contar con la autorización de sus padres y/o
                            representante legal.
                        </li>
                        <li>
                            Acceder de manera ilegítima a la plataforma vulnerando las medidas de
                            seguridad de la misma.
                        </li>
                    </ul>
                    <p>
                        La Sociedad procederá a notificar a los usuarios mediante correo
                        electrónico, las razones por las cuales se decidió desactivar la cuenta de
                        los usuarios.
                    </p>
                    <p>&nbsp;</p>
                    <h3>REPORTE DE CONTENIDOS DE USUARIO</h3>
                    <p>
                        Si considera que algún contenido publicado por un usuario infringe sus
                        derechos de propiedad intelectual, de terceros u otros derechos, puede
                        enviar una notificación al correo electrónico{" "}
                        <a href="mailto:contacto@soccermarketing.com">
                            contacto@soccermarketing.com,
                        </a>{" "}
                        La sociedad permitirá al presunto infractor enviar una respuesta al Usuario
                        reclamante para solucionar la controversia. La Sociedad podrá, a su
                        exclusivo criterio, eliminar el Contenido de la plataforma o tomar otras
                        medidas que considere apropiadas como la suspensión de la cuenta
                        temporalmente, sin notificación previa al usuario o alguna otra parte que
                        haya notificado ese Contenido.
                    </p>
                    <h2>
                        IV. RESPONSABILIDADES EN EL USO Y ACCESO PRIVACIDAD Y COMPETENCIA LEAL
                    </h2>
                    <p>
                        Al registrarse en la plataforma, usted otorga su consentimiento para la
                        recopilación y procesamiento de toda la información personal o no
                        suministrada. Es por lo anterior que manejará esta información de acuerdo
                        con lo establecido en la norma de habeas data de la legislación colombiana,
                        la cual ha sido expresamente incorporada en este documento.&nbsp;
                    </p>
                    <p>
                        De igual forma, La Sociedad actuará bajo los lineamientos impuestos por el
                        ordenamiento jurídico colombiano, para que cada uno de los usuarios compitan
                    </p>
                    <p>
                        lealmente, razón por la cual se encuentra completamente prohibido incurrir
                        en cualquier acto desleal por parte de La Sociedad o los usuarios. Se
                        entiende como acto desleal alguno de los siguientes:
                    </p>
                    <ul>
                        <li>Actos de desviación de la clientela</li>
                        <li>Actos de desorganización</li>
                        <li>Actos de confusión</li>
                        <li>Actos de engaño</li>
                        <li>Actos de descrédito</li>
                        <li>Actos de comparación</li>
                        <li>Actos de imitación</li>
                        <li>Explotación de la reputación ajena</li>
                        <li>Violación de secretos</li>
                        <li>Inducción a la ruptura contractual</li>
                        <li>Violación de normas</li>
                        <li>Pactos desleales de exclusividad</li>
                    </ul>
                    <p>
                        Al aceptar estos Términos y Condiciones, usted acepta los términos de
                        privacidad y competencia desleal.
                    </p>
                    <p>&nbsp;</p>
                    <h3>EXACTITUD DE LA INFORMACIÓN</h3>
                    <p>
                        Usted declara y garantiza que toda la información que proporciona a La
                        Sociedad es correcta, completa y vigente, y se compromete a actualizar dicha
                        información cuando sea necesario. De igual forma, La Sociedad se obliga a
                        cumplir con los dispuesto en la legislación colombiana respecto del Habeas
                        Data, con la finalidad de proteger la información proporcionada por usted,
                        así como se espera que el usuario se rija por las mismas obligaciones al
                        momento de utilizar y tratar la información de La Sociedad.
                    </p>
                    <p>&nbsp;</p>
                    <h3>CERTIFICACIÓN DE RESIDENCIA Y CAPACIDAD PARA CELEBRAR CONTRATOS</h3>
                    <p>
                        Al registrarse en la plataforma <strong>SOCCER MARKETING</strong>, usted
                        declara y garantiza que reside en el territorio que ha indicado en su en el
                        formulario de validación de datos de la plataforma. Así mismo, declara que
                        tiene capacidad para aceptar estos Términos y Condiciones de conformidad con
                        las leyes de su jurisdicción.
                    </p>
                    <p>&nbsp;</p>
                    <h3>NATURALEZA DEL CONTENIDO</h3>
                    <p>
                        Usted entiende que al utilizar la plataforma y tener acceso al contenido de{" "}
                        <strong>SOCCER MARKETING</strong>, no podrá compartir el contenido que se
                        encuentra limitado en el punto{" "}
                        <strong>
                            III<em>.</em>
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <em>RESTRICCIONES 3. Conducta Prohibida</em>
                        </strong>
                        , buscando la protección de los menores de edad y del material que vulnera
                        los derechos de terceros.
                    </p>
                    <p>
                        De igual forma, vale la pena tener en cuenta que La Sociedad subirá
                        fotografías, música, títulos y marcas de las obras de titularidad de los
                        usuarios las cuales se encuentran protegidas por la Decisión 486 de la
                        Comisión de la Comunidad Andina y la Ley 23 de 1983.
                    </p>
                    <p>
                        Es por lo anterior que La Sociedad se exime de responsabilidad cuando los
                        verdaderos titulares de los derechos patrimoniales de autor y conexos
                        presente cualquier reclamación a La Sociedad por la infracción de dichos
                        derechos. Como consecuencia, la responsabilidad recaerá directa y
                        expresamente en los usuarios, quienes declararon en el contrato de mandato y
                        en este documento, al aceptar los Términos y Condiciones, ser los titulares
                        legítimos de los derechos patrimoniales de autor y conexos.
                    </p>
                    <p>&nbsp;</p>
                    <h3>
                        RIESGO DE USO<strong>&nbsp;</strong>
                    </h3>
                    <ul>
                        <li>
                            La Sociedad no garantiza que el servicio sea compatible con el o los
                            dispositivos destinados para adquirir y usar la plataforma, razón por la
                            cual usted es el único responsable de asegurarse de que sus sistemas
                            funcionen correctamente con el
                        </li>
                        <li>
                            La Sociedad no asumirá responsabilidad alguna por ningún daño o virus en
                            su computadora, celular u otra propiedad como consecuencia del acceso,
                            uso, descarga o navegación por la plataforma{" "}
                            <strong>SOCCER MARKETING</strong>.
                        </li>
                        <li>
                            En ningún caso, La Sociedad será responsable por daños y perjuicios que
                            surjan de y/o en relación con la plataforma y estos términos y
                            condiciones, entre otros:
                            <ul>
                                <li>Daños y perjuicios derivados de la pérdida de datos</li>
                                <li>
                                    Lucro cesante y daño emergente por pérdida del uso de la plataforma
                                </li>
                                <li>
                                    Daños y perjuicios derivados del uso que usted haga del servicio y que
                                    infrinja estos Términos y Es por esto que bajo ninguna circunstancia
                                    La Sociedad no será responsable por el uso no autorizado de cualquier
                                    contenido o cualquier uso del servicio para desarrollar, distribuir o
                                    utilizar cualquier material que sea difamatorio, calumnioso, injurioso
                                    u obsceno, que constituya una invasión de cualquier derecho a la
                                    privacidad o una infracción de cualquier derecho a la publicidad, que
                                    viole o infrinja los derechos de cualquier tercero o que vulnere
                                    cualquier ley o regulación extranjera, federal, estatal o local.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <h3>EL DERECHO DE LA SOCIEDAD PARA CAMBIAR ESTOS TÉRMINOS Y CONDICIONES</h3>
                    <ul>
                        <li>
                            La Sociedad se reserva el derecho de cambiar, modificar, añadir o
                            eliminar, en cualquier momento, partes de estos Términos y Condiciones,
                            sin previo aviso, entrando en vigor inmediatamente y entendiéndose
                            aceptados con el solo uso de la plataforma.
                        </li>
                        <li>
                            Si llegara a existir un cambio sustancial, la sociedad hará todos los
                            esfuerzos comercialmente razonables, para notificar al Usuario y obtener
                            su consentimiento antes de implementarlos.
                        </li>
                        <li>
                            Es obligación del Usuario consultar estos Términos y Condiciones de forma
                            periódica.
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <h3>EL DERECHO DE LA SOCIEDAD PARA EFECTUAR CAMBIOS A LA PLATAFORMA</h3>
                    <p>
                        La Sociedad puede agregar, cambiar, terminar, remover o suspender cualquier
                        material incorporado a la plataforma, incluyendo características y
                        especificaciones de los planes descritos o reseñados en el presente
                        documento, de forma temporal o permanente, en cualquier momento, sin previo
                        aviso y eximiéndose de responsabilidad.
                    </p>
                    <p>
                        Los cambios de precio entrarán en vigor al comienzo del próximo período de
                        suscripción posterior a la fecha del cambio de precio. Se considerará
                        aceptado el nuevo precio al continuar usando el Servicio brindado por La
                        Sociedad después de que el cambio de precio entre en vigor.
                    </p>
                    <p>&nbsp;</p>
                    <h2>V. GENERALIDADES DE LOS TÉRMINOS Y CONDICIONES RECURSOS</h2>
                    <p>
                        Usted acuerda que cualquier uso no autorizado de la plataforma, de su
                        contenido o de cualquier tecnología contenida en el mismo, que ocasione
                        algún perjuicio irreparable a La Sociedad, un usuario y/o a un tercero,
                        deberá compensar los daños y perjuicios, sin perjuicio de que el afectado
                        tendrá la facultad de ejercer la acción correspondiente en contra de usted,
                        buscando la reparación integral de los daños ocasionados.
                    </p>
                    <p>&nbsp;</p>
                    <h3>INDEMNIZACIÓN</h3>
                    <p>
                        Ante cualquier incumplimiento de alguna de las obligaciones que este
                        documento contiene, usted deberá indemnizar integralmente a La Sociedad y
                        eximir de toda responsabilidad a la misma respecto a toda reclamación,
                        pérdida, daño y perjuicio, responsabilidades, deficiencias, gravámenes,
                        multas, costas, gastos de honorarios de abogados y entre otros gastos que
                        surjan en la relación directa o indirectamente del uso de la plataforma.
                        Como consecuencia de lo anterior, el usuario deberá responder por los daños
                        pecuniarios y/o morales de los que se vea La Sociedad o sus trabajadores y
                        demás sanciones que sean impuestas judicialmente.
                    </p>
                    <p>&nbsp;</p>
                    <h3>
                        TERMINACIÓN<strong>&nbsp;</strong>
                    </h3>
                    <p>
                        El plazo de estos Términos y Condiciones se mantendrá vigente mientras el
                        usuario mantenga vigente su vinculación en la aplicación, hasta que se dé
                        alguna de las causales de terminación de prestación de la misma y/o por el
                        incumplimiento de alguna de las obligaciones contenidas en el presente
                        documento.
                    </p>
                    <p>
                        Una vez terminada esta relación jurídica, usted debe dejar de utilizar por
                        completo la plataforma y eximir de cualquier responsabilidad a La Sociedad
                        <strong>, </strong>sin perjuicio de las responsabilidades que se incluyeron
                        en el contrato de mandato suscrito entre las partes.
                    </p>
                    <p>&nbsp;</p>
                    <h3>ELECCIÓN DE DERECHO Y JURISDICCIÓN TERRITORIAL</h3>
                    <p>
                        Estos Términos y Condiciones se regirán de conformidad con las leyes
                        colombianas y de la siguiente:
                    </p>
                    <h3>CLÁUSULA COMPROMISORIA</h3>
                    <p>
                        Toda controversia o diferencia relativa a estos Términos y Condiciones y las
                        que surgieran en temas relacionados directa o indirectamente con derechos de
                        autor y/o conexos que surgieran entre las partes, se resolverán por un
                        Tribunal de Arbitramento que funcionará en el Centro de Conciliación y
                        Arbitraje “Fernando Hinestroza” de la Dirección Nacional de Derechos de
                        Autor y toda diferencia que surja en relación con cualquier otra situación
                        derivada del presente contrato, que no tenga que ver con temas de derechos
                        de autor y/o conexos, se resolverán por el Tribunal de Arbitramento del
                        Centro de Conciliación y Arbitraje de la Cámara de Comercio de Bogotá.
                    </p>
                    <p>
                        Cualquiera de los Tribunales se regirá por el procedimiento dispuesto en la
                        Ley 1563 del 2012 y a las demás disposiciones legales que los reglamenten,
                        adicionen o modifiquen y de acuerdo con las siguientes reglas:
                    </p>
                    <ul>
                        <li>
                            El Tribunal estará integrado por un (1) árbitro, si es por mínima o menor
                            cuantía, que será nombrado por las partes en conflicto de común acuerdo y
                            si no llegare a haber acuerdo, se escogerá por sorteo de las listas de
                            árbitros del Centro de Conciliación y Arbitraje competente para el asunto.
                        </li>
                        <li>
                            El Tribunal estará integrado por tres (3) árbitros si es por mayor
                            cuantía, los cuales serán nombrados por las partes en conflicto de común
                            acuerdo y si no llegare a haber acuerdo, se escogerán por sorteo de las
                            listas de árbitros del Centro de Conciliación y Arbitraje competente para
                            el asunto.
                        </li>
                        <li>El Tribunal decidirá en derecho.</li>
                    </ul>
                    <p>
                        En el evento de que exista algún elemento extranjero en los conflictos
                        estipulados en la presente cláusula, en virtud de esta misma cláusula, se
                        podrá acudir a arbitraje internacional, según lo dispuesto en el artículo 62
                        de la Ley 1563 de 2012.
                    </p>
                    <p>
                        Si el arbitraje es internacional, el Tribunal Arbitral funcionará en el
                        Centro de Conciliación y Arbitraje de la Cámara de Comercio de Bogotá se
                        regirá por las siguientes reglas:&nbsp;
                    </p>
                    <p>
                        El Tribunal se sujetará al Reglamento de Arbitraje Comercial Internacional
                        del Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá.
                    </p>
                    <ul>
                        <li>
                            El Tribunal estará integrado por un (1) árbitro, si es por mínima o menor
                            cuantía, que será nombrado por las partes en conflicto de común acuerdo y
                            si no llegare a haber acuerdo, se escogerá por sorteo de las listas de
                            árbitros del Centro de Conciliación y Arbitraje competente para el asunto.
                        </li>
                        <li>
                            El Tribunal estará integrado por tres (3) árbitros si es por mayor
                            cuantía, los cuales serán nombrados por las partes en conflicto de común
                            acuerdo y si no llegare a haber acuerdo, se escogerán por sorteo de las
                            listas de árbitros del Centro de Conciliación y Arbitraje competente para
                            el asunto.
                        </li>
                        <li>El Tribunal decidirá en derecho.</li>
                        <li>El Tribunal conocerá en español (Castellano).</li>
                        <li>
                            Las leyes de fondo aplicables serán las de la República de Colombia.
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <h3>CONVENIO TOTAL Y DIVISIBILIDAD</h3>
                    <p>
                        Estos Términos y Condiciones constituyen el acuerdo total entre usted y La
                        Sociedad<strong>, </strong>reemplazando cualquier otro documento, convenio y
                        entendimiento previo y/o contemporáneo, ya sea escritos u orales, que haya
                        existido entre las partes. Salvo lo expresamente establecido en el presente
                        documento, estos Términos y Condiciones no podrán ser enmendados,
                        modificados o complementados por las partes, excepto mediante instrumento
                        por escrito firmado por un directivo autorizado de La Sociedad. De manera
                        complementaria, se entiende que no se ha renunciado a ninguna disposición de
                        este documento, y si así lo quisiera La Sociedad debería realizarlo de
                        manera escrita, firmada por un directivo autorizado y notificando a los
                        usuarios.
                    </p>
                    <p>
                        De igual forma, si el Tribunal de Arbitramento de la Cámara de Comercio
                        determinara que una parte de estos Términos y Condiciones es inválida o
                        inexigible, el resto de estos términos tendrán validez jurídica y deberán
                        ser interpretados de modo que refleje razonablemente la intención de las
                        partes.
                    </p>
                    <p>&nbsp;</p>
                    <h3>CESIÓN DE LOS TÉRMINOS Y CONDICIONES</h3>
                    <p>
                        En cuanto a la cesión de las obligaciones, estos Términos y Condiciones
                        podrán ser cedidos por La Sociedad, siempre y cuando se hayan notificado al
                        usuario. Este derecho no puede ser ejercido por el usuario, bien sea total o
                        parcialmente, sin el consentimiento previo por escrito de un directivo
                        autorizado de La Sociedad.
                    </p>
                    <p>&nbsp;</p>
                    <h3>
                        PROPIETARIO&nbsp;&nbsp;&nbsp; POSTERIOR&nbsp;&nbsp;&nbsp;
                        DEL&nbsp;&nbsp;&nbsp; SERVICIO;&nbsp;&nbsp;&nbsp;
                        FUSIÓN/ADQUISICIÓN&nbsp;&nbsp;&nbsp; DE&nbsp;&nbsp;&nbsp; LA SOCIEDAD
                    </h3>
                    <p>
                        Al utilizar la plataforma, usted acuerda que La Sociedad o una de sus
                        compañías podrán entrar en proceso de fusión y adquisición o vender la
                        plataforma, ceder sus derechos y obligaciones en relación con la plataforma.
                        En caso de realizarse dicha fusión, adquisición, venta o cesión, la
                        plataforma seguirá funcionando y estos Términos y Condiciones seguirán
                        vigentes hasta que el nuevo propietario considere lo contrario.
                    </p>
                    <p>&nbsp;</p>
                    <h2>VI. PROPIEDAD INTELECTUAL</h2>
                    <h3>PROPIEDAD INDUSTRIAL</h3>
                    <p>
                        La plataforma contiene y/o incorpora secretos comerciales, marcas, inventos
                        patentados, diseños industriales, modelos de utilidad, lemas comerciales,
                        nombres comerciales, enseñas comerciales, entre otros derechos cuyo titular
                        es La Sociedad y/o los usuarios, derechos protegidos por la propiedad
                        industrial en la Decisión 486 de la Comisión de la Comunidad Andina.
                    </p>
                    <p>
                        Es por lo anterior que usted no puede divulgar, replicar, modificar, copiar,
                        distribuir, reproducir, reconfigurar, descomponer, o transformar total o
                        parcialmente esta plataforma ya que su contenido es de propiedad exclusiva
                        de La Sociedad, los usuarios y/o quienes sean titulares de las respectivas
                        licencias.
                    </p>
                    <p>
                        Los usuarios serán responsables por la vulneración de los derechos que aquí
                        fueron descritos y tendrán que responder de acuerdo con lo establecido en la
                        Decisión 486 de la Comisión de la Comunidad Andina.
                    </p>
                    <p>&nbsp;</p>
                    <h3>DERECHOS DE AUTOR</h3>
                    <p>
                        El contenido de la plataforma y otras obras que se encuentran en la
                        plataforma que forman parte del mismo, están protegidos por el derecho de
                        autor en la Ley 23 de 1982, la Ley 1915 de 2018, el Convenio de Berna y todo
                        otro tratado internacional que haya sido ratificado por Colombia. Teniendo
                        en cuenta que La Sociedad y/o los usuarios son titulares de los derechos
                        patrimoniales de autor y/o conexos de las obras contenidas en la plataforma,
                        usted no puede realizar copias no autorizadas, reproducir, transformar,
                        divulgar, vender, reproducir, traducir, etc., la plataforma o el contenido
                        de la misma so pena de incurrir en la vulneración de los derechos que recaen
                        en La Sociedad, los usuarios y/o los verdaderos titulares de los derechos
                        patrimoniales de autor y conexos sobre las obras en cuestión. Es por esto
                        que se encuentra rotundamente prohibido subir fotos con las camisetas de
                        diferentes equipos del fútbol a nivel mundial.
                    </p>
                    <p>
                        Adicionalmente, usted podrá incurrir en el tipo penal de violación a los
                        derechos morales de autor (artículo 270 del Código Penal Colombiano) y la
                        violación de los mecanismos de protección de los derechos patrimoniales de
                        autor y otras deformaciones (Artículo 272 del Código Penal Colombiano)
                    </p>
                    <p>
                        Por lo anterior, usted acepta cumplir con todas las leyes aplicables para la
                        protección de derechos de autor en relación con el uso de la plataforma y su
                        contenido.
                    </p>
                    <p>
                        En el momento de incurrir en alguna de las anteriores actividades, La
                        Sociedad, los usuarios y/o el titular legítimo de los derechos, tomará las
                        acciones correspondientes para que usted pueda resarcir los daños
                        ocasionados.
                    </p>
                    <p>&nbsp;</p>
                    <h3>MARCAS COMERCIALES</h3>
                    <p>
                        No puede utilizar ninguna de las marcas comerciales que se exponen en la
                        plataforma, salvo lo especificado en el presente documento y de conformidad
                        con la Decisión 486 de la Comisión de la Comunidad Andina en su Título VI.
                        Es por lo anterior que no se permite que usted:
                    </p>
                    <ul>
                        <li>
                            Aplique o coloque la marca o un signo distintivo idéntico o semejante
                            sobre productos para los cuales se ha registrado la marca; sobre productos
                            vinculados a los servicios para los cuales esta se ha registrado; o sobre
                            los envases, envolturas, embalajes o acondicionamientos de tales
                            productos;
                        </li>
                        <li>
                            Suprima o modifique la marca con fines comerciales, después de que se
                            hubiese aplicado o colocado sobre los productos para los cuales se ha
                            registrado la marca; sobre los productos vinculados a los servicios para
                            los cuales está se ha registrado; o sobre los envases, envolturas,
                            embalajes o acondicionamientos de tales productos;
                        </li>
                        <li>
                            Fabrique etiquetas, envases, envolturas, embalajes u otros materiales que
                            reproduzcan o contengan la marca, así́ como comercializar o detentar tales
                            materiales;
                        </li>
                        <li>
                            Use en el comercio un signo idéntico o similar a la marca respecto de
                            cualesquiera productos o servicios, cuando tal uso pudiese causar
                            confusión o un riesgo de asociación con el titular del Tratándose del uso
                            de un signo idéntico para productos o servicios idénticos se presumirá que
                            existe riesgo de confusión;
                        </li>
                        <li>
                            Use en el comercio un signo idéntico o similar a una marca notoriamente
                            conocida respecto de cualesquiera productos o servicios, cuando ello
                            pudiese causar al titular del registro un daño económico o comercial
                            injusto por razón de una dilución de la fuerza distintiva o del valor
                            comercial o publicitario de la marca, o por razón de un aprovechamiento
                            injusto del prestigio de la marca o de su titular;
                        </li>
                        <li>
                            Use públicamente un signo idéntico o similar a una marca notoriamente
                            conocida, aun para fines no comerciales, cuando ello pudiese causar una
                            dilución de la fuerza distintiva o del valor comercial o publicitario de
                            la marca, o un aprovechamiento injusto de su prestigio.
                        </li>
                    </ul>
                    <p>
                        En el momento de incurrir en alguna de las anteriores actividades, La
                        Sociedad y/o el titular de los derechos, tomarán las acciones
                        correspondientes para que usted pueda resarcir los daños ocasionados.
                    </p>
                    <p>&nbsp;</p>
                    <h3>DERECHOS DE LA SOCIEDAD RESPECTO A LOS MATERIALES QUE USTED PUBLICA</h3>
                    <p>
                        La Sociedad publicará todo el contenido visual, audiovisual que los usuarios
                        suban a la plataforma, eximiéndose de responsabilidad por alguna vulneración
                        de los derechos de autor, conexos, derecho marcario, de patentes, modelo de
                        utilidad o cualquier otro derecho que haga parte de la propiedad industrial,
                        puesto que asume que los usuarios cuentan con los permisos correspondientes
                        y/o son los titulares de los derechos.
                    </p>
                    <p>&nbsp;</p>
                    <h2>VII. DEFINICIONES</h2>
                    <ul>
                        <li>
                            "Usuarios" de la siguiente manera:
                            <ul>
                                <li>
                                    Las personas naturales que tengan calidad de jugadores entre los 12 y
                                    17 años, siempre que sus padres y/o representantes legales hayan
                                    registrado y/o autorizado a sus hijos hacer parte de{" "}
                                    <strong>SOCCER MARKETING</strong>.
                                </li>
                                <li>
                                    Las personas naturales, hombres y mujeres, que quieran debutar
                                    profesionalmente y se encuentren entre los 18 y 25 años.
                                </li>
                                <li>
                                    Las personas naturales, hombres y mujeres que se encuentren entre los
                                    13 y 40 años que tienen una carrera deportiva profesional
                                </li>
                                <li>
                                    Las personas jurídicas y/o naturales que actúen en calidad de agentes
                                </li>
                                <li>
                                    Las personas naturales que tengan calidad de técnicos de fútbol.
                                </li>
                            </ul>
                        </li>
                        <li>
                            "Contenido de “<strong>SOCCER MARKETING</strong>" significa la música,
                            videos, imágenes, texto, datos personales y otros materiales que usted
                            puede obtener o a los cuales puede acceder en forma digital desde la
                            plataforma, cuyo contenido es propiedad de La Sociedad o de terceros
                            licenciadores.
                        </li>
                        <li>
                            "Registro de validación" significa la información que usted proporciona a
                            La Sociedad posterior al registro de los usuarios en la plataforma.
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <h2>VIII. PREGUNTAS</h2>
                    <p>
                        Si tiene alguna pregunta o comentario sobre estos Términos y Condiciones, no
                        dude en contactarnos mediante el siguiente correo{" "}
                        <a href="mailto:contacto@soccermarketing.com">
                            contacto@soccermarketing.com
                        </a>
                    </p>
                    <p>
                        <strong>SB DEVELOPERS S.A.S. </strong>Calle 145 No. 12 86 Of 101 Bogotá,
                        Colombia
                    </p>
                    <p>
                        Se reserva cualquier derecho que no esté expresamente otorgado en el
                        presente documento.
                    </p>
                </div>
            </div>
        </div>

    )
}

export default TerminosCondiciones