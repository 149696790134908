import React, { useEffect } from 'react';
import '../../styles/politicas.scss'
import sm from '../../assets/ic_sm_horizontal.png'
import { Link } from 'react-router-dom'

const Politicas = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="fondoPoliticas">
            <div className="topImagen">
                <Link to="/" className="urlImagen"><img src={sm} alt="logo" /></Link>
            </div>
            <div className="topPoliticas">POLÍTICAS DE PRIVACIDAD</div>
            <div className='archivo'>
                <div className='contenedor-archivo'>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "left",
                            border: "none",
                            color: "white"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                USO, MANEJO Y ADMINISTRACIÓN DE DATOS
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Como consecuencia del acceso y utilización de la plataforma, el usuario
                            autoriza expresamente a La Sociedad<strong>&nbsp;</strong>de hacer uso de
                            su información personal, tales como correo electrónico, cuenta bancaria,
                            número de identificación (cédula de ciudadanía, extranjería, número de
                            pasaporte, tarjeta de identidad), nombre, apellido, hoja de vida
                            identificando su trayectoria profesional, con el fin de que los clubes
                            vinculados a la plataforma puedan tener de primera mano la información más
                            importante para tomar la decisión que más se adecúe a su
                            expectativas.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Lo anterior con el propósito de que La Sociedad<strong>&nbsp;</strong>
                            pueda garantizar el correcto manejo a la información personal de los
                            menores de edad, y a su vez entregar la información completa a los clubes
                            y servir como entre los jugadores, agentes, técnicos y los clubes y/o
                            asociaciones a las que haya lugar.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            En el mismo sentido, el usuario autoriza a{" "}
                            <strong>SOCCER MARKETING&nbsp;</strong>a:
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            start={1}
                            style={{
                                marginBottom: "0cm",
                                listStyleType: "lower-alpha",
                                marginLeft: 26
                            }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <span style={{ color: "white" }}>
                                    Actualizar, modificar e instalar el software, de manera automática o
                                    requerida, entre otras razones para abordar cuestiones de seguridad,
                                    interoperabilidad y/o funcionamiento. Estas actualizaciones,
                                    modificaciones y operaciones similares pueden realizarse en forma
                                    periódica o cuando sea necesario, sin previo aviso.&nbsp;
                                </span>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "36.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            start={2}
                            style={{
                                marginBottom: "0cm",
                                listStyleType: "lower-alpha",
                                marginLeft: 26
                            }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <span style={{ color: "white" }}>
                                    Crear un protocolo de notificación que se enviará al correo de los
                                    padres y/o los representantes legales del menor de edad, para informar
                                    las siguientes circunstancias:
                                </span>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "36.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <ul style={{ listStyleType: "undefined", marginLeft: 62, fontSize: 16, }}>
                        <li>
                            <span style={{ color: "white", fontSize: 16, }}>
                                El registro a la plataforma&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white", fontSize: 16, }}>
                                Los mensajes enviados por los clubes y/o asociaciones al menor de
                                edad&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white", fontSize: 16, }}>
                                Una copia de los Términos y Condiciones&nbsp;
                            </span>
                        </li>
                    </ul>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            start={3}
                            style={{
                                marginBottom: "0cm",
                                listStyleType: "lower-alpha",
                                marginLeft: 26
                            }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <span style={{ color: "white" }}>
                                    Vender, sublicenciar y publicar los datos personales siempre que se
                                    encuentren en relación directa y/o indirecta con los servicios
                                    proporcionados por La Sociedad
                                </span>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "36.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            La Sociedad se ceñirá<strong>&nbsp;</strong>a lo establecido en
                            <strong>&nbsp;</strong>la Ley 1581 de 2012 y el Decreto 1377 de 2013 de la
                            legislación colombiana, para dar el adecuado tratamiento de los datos
                            personales, tal y como se muestra a continuación:&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol style={{ marginBottom: "0cm", listStyleType: "decimal" }}>
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white", fontSize: 16, }}>
                                        Obligaciones de los responsables del tratamiento de datos
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "36.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <ol start={1} style={{ listStyleType: "lower-alpha", fontSize: 16, marginLeft: "43.45px" }}>
                        <li>
                            <span style={{ color: "white" }}>
                                Garantizar al usuario el uso efectivo, plena y en todo tiempo de los
                                derechos de hábeas data;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Impedir la adulteración de la información, pérdida, consulta, uso o
                                acceso no autorizado o fraudulento;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Respetar las condiciones de seguridad y privacidad de la información del
                                usuario;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Informar a la autoridad de protección de datos, Superintendencia de
                                Industria y Comercio (SIC), cuando se presenten violaciones a los
                                códigos de seguridad y existan riesgos en la administración de la
                                información de los usuarios.
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Cumplir las instrucciones y requerimientos que imparta la
                                Superintendencia de Industria y Comercio.
                            </span>
                        </li>
                    </ol>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "36.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol style={{ marginBottom: "0cm", fontSize: 16, listStyleType: "decimal" }}>
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        Obligaciones de los encargados del tratamiento de datos
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol start={1} style={{ listStyleType: "lower-alpha", fontSize: 16, marginLeft: "43.45px" }}>
                        <li>
                            <span style={{ color: "white" }}>
                                Garantizar al usuario el uso efectivo, plena y en todo tiempo de los
                                derechos de hábeas data;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Impedir la adulteración de la información, pérdida, consulta, uso o
                                acceso no autorizado o fraudulento;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Abstenerse de circular información que esté siendo controvertida por el
                                usuario y cuyo bloqueo haya sido ordenado por la Superintendencia de
                                Industria y Comercio;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Permitir el acceso a la información únicamente a las personas que pueden
                                tener acceso a ella;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Informar a la Superintendencia de Industria y Comercio cuando se
                                presenten violaciones a los códigos de seguridad y existan riesgos en la
                                administración de la información de los Titulares;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Cumplir las instrucciones y requerimientos que imparta la
                                Superintendencia de Industria y Comercio.
                            </span>
                        </li>
                    </ol>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            En ese sentido<strong>,&nbsp;</strong>La Sociedad<strong>&nbsp;</strong>se
                            exime de cualquier responsabilidad derivada del incumplimiento de alguna
                            de sus obligaciones tendientes a la protección y tratamiento de datos,
                            siempre que no exista plena prueba que demuestre el daño ocasionado al
                            usuario o que este último haya ocasionado su propio perjuicio de manera
                            dolosa o culposa.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            style={{ marginBottom: "0cm", listStyleType: "undefined", marginLeft: 8 }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        SEGURIDAD DE LA INFORMACIÓN
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            La Sociedad<strong>&nbsp;</strong>se asegurará de actuar diligentemente
                            para evitar e impedir hurto, fraude, filtraciones, uso inadecuado de la
                            información. Para lo anterior, los trabajadores, representante legal
                            principal y/o suplente, miembros de la Junta Directiva, proveedores o
                            cualquier otra persona que tenga un vínculo contractual con esta
                            <strong>,&nbsp;</strong>deberán cumplir con las políticas, normas,
                            procedimientos y buenas prácticas de seguridad de la información
                            establecidas por La Sociedad.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Adicionalmente, se garantizará un respaldo de información cada
                            veinticuatro (24) horas, la cual estará disponible para los usuarios
                            cuando estos lo requieran.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            style={{ marginBottom: "0cm", listStyleType: "undefined", marginLeft: 8 }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        REQUISITO DE EDAD
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            De acuerdo con la Ley 1581 de 2012 en el art. 2.2.8.4.2.4. de la
                            legislación colombiana, se ha establecido la prohibición del tratamiento
                            de los datos personales de menores de 18 años o los menores de edad de
                            acuerdo con la jurisdicción en la que se encuentre el usuario, sin previa
                            autorización de su padre, madre o cualquier persona que tenga la custodia
                            del menor y que actúe como su representante legal. En ese sentido, si
                            usted es menor de 18 años o menor de edad, y pretende acceder a la
                            plataforma, De manera obligatoria, e imprescindible su padre, madre, tutor
                            o representante legal deben aceptar estos Términos y Condiciones y
                            suministrar la información que se solicitan en el formulario.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            De igual forma, Cuando se trate de menor de 14 años, por ser incapaces
                            absolutos en la legislación colombiana, o el equivalente en la
                            jurisdicción en la que se encuentre el usuario, no deberá proporcionar
                            información alguna a La Sociedad ni a las asociaciones y/o clubes sin que
                            sus padres, tutor y/o representante legal lo permita, ya sea mediante el
                            uso de la plataforma o a través de cualquier otra actividad con ocasión
                            del desarrollo de la plataforma.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            style={{ marginBottom: "0cm", listStyleType: "undefined", marginLeft: 8 }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        CONDUCTAS PROHIBIDAS
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "18.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Se considera una conducta grave y se encuentra completamente prohibido
                            para los proveedores, administradores y usuarios realizar las siguientes
                            actividades:
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol start={1} style={{ listStyleType: "lower-alpha", fontSize: 16, marginLeft: 26 }}>
                        <li>
                            <span style={{ color: "white" }}>
                                Alojar en su propio sitio imágenes, textos, documentos o archivos
                                audiovisuales que impliquen directa o indirectamente actividades
                                sexuales con menores de edad.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Alojar en su propio sitio material pornográfico, en especial en modo de
                                imágenes o videos, cuando existan indicios de que las personas
                                fotografiadas o filmadas son menores de edad.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Alojar en su propio sitio vínculos o links, sobre sitios telemáticos que
                                contengan o distribuyan material pornográfico relativo a menores de
                                edad.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Alojar datos personales de los menores de catorce (14) años o los
                                menores de edad de acuerdo con la jurisdicción en la que se encuentre el
                                usuario, sin previa autorización de sus padres, tutor y/o representante
                                legal.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Alojar los datos personales de los menores de dieciocho (18) años o los
                                menores de edad de acuerdo con la jurisdicción en la que se encuentre el
                                usuario, sin previa autorización de su papá, mamá o quien tenga la
                                custodia del menor.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Transmitir, divulgar, transformar o poner a disposición pública
                                cualquier contenido o material que viole o infrinja derechos de autor,
                                marcas registradas, patentes, secretos comerciales y otros derechos de
                                propiedad intelectual, derechos de privacidad o publicidad, reglamentos
                                o estatutos de comunicaciones, o cualesquiera otras leyes, incluyendo,
                                sin limitación, las leyes sobre difamación, acoso, obscenidad y
                                pornografía.
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Divulgar campañas políticas, contenido con virus informáticos u otro
                                código de computadora destinado a interferir con la funcionalidad de los
                                sistemas de computadoras.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Interrumpir ni intentar interrumpir la operación de la plataforma de
                                ninguna manera o hacer uso de una dirección de correo electrónico falso
                                con la finalidad de engañar a otros miembros en cuanto a su identidad o
                                al origen de un mensaje o contenido.
                            </span>
                        </li>
                    </ol>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Es obligación del Usuario hacer un uso adecuado y respetuoso de la
                            plataforma. Cualquier violación a lo aquí dispuesto, estará sujeta a la
                            revisión de La Sociedad<strong>&nbsp;</strong>para adoptar las acciones
                            legales correspondientes.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "18.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol style={{ marginBottom: "0cm", listStyleType: "undefined" }}>
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        Restricción sobre el uso de la plataforma y su contenido
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "18.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            La plataforma incluye componentes de seguridad por lo que se aplican
                            normas y políticas especiales, que determinan que usted no deberá intentar
                            eludir, aplicar ingeniería inversa, descifrar, descompilar, desmontar o de
                            algún modo modificar, alterar o interferir con ningún aspecto de la
                            plataforma. En el mismo sentido, usted no podrá distribuir, intercambiar,
                            modificar, vender o revender, o transmitir a cualquier otra persona
                            cualquier parte de la plataforma, incluyendo, sin limitación, cualquier
                            texto, imagen, audio o video para cualquier propósito empresarial,
                            comercial o público. Usted conviene en no copiar, vender, distribuir o de
                            algún modo transferir el contenido de la plataforma{" "}
                            <strong>SOCCER MARKETING</strong> salvo que exista una licencia que así se
                            lo permita.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            start={1}
                            style={{
                                marginBottom: "0cm",
                                listStyleType: "lower-alpha",
                                marginLeft: 8
                            }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        Restricción sobre los contenidos de&nbsp;SOCCER MARKETING
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            marginLeft: "18.0pt",
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            El usuario NO podrá ejercer los siguientes derechos:
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <ul style={{ listStyleType: "undefined", fontSize: 16, marginLeft: 26 }}>
                        <li>
                            <span style={{ color: "white" }}>
                                Derechos de reproducción o uso promocional en el contenido de{" "}
                                <strong>SOCCER MARKETING</strong>.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Derechos para celebrar ningún contrato siendo intermediario del club y/o
                                asociación.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Derechos de distribuir o redistribuir el contenido de{" "}
                                <strong>SOCCER MARKETING&nbsp;</strong>en aplicaciones de streaming (vía
                                Internet, intranets y/u otras redes),&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Derechos de distribuir a otras personas el contenido de{" "}
                                <strong>SOCCER MARKETING</strong> otros sistemas de distribución de
                                contenido o en medios físicos (discos compactos, discos digitales
                                versátiles, chips semiconductores, discos duros, tarjetas de memoria y
                                similares)
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Derechos de uso comercial, venta, reventa, reproducción, distribución o
                                promocional del contenido de <strong>SOCCER MARKETING</strong>
                            </span>
                        </li>
                    </ul>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            start={2}
                            style={{
                                marginBottom: "0cm",
                                listStyleType: "lower-alpha",
                                marginLeft: 8
                            }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        Restricción sobre los contenidos de los Usuarios&nbsp;
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Ni los usuarios, ni cualquier otra persona, podrá hacer uso de los videos
                            que hayan sido subidos a la plataforma. No podrán ser comercializados,
                            vendidos, licenciados, sublicenciados, prestados, arrendados ni cualquier
                            otra modalidad de transferencia de la propiedad.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            En caso de que esto suceda, el usuario podrá hacer valer su derecho
                            ejerciendo las acciones correspondientes a la infracción del derecho de
                            autor, uso de imagen personal, responsabilidad extracontractual y/o
                            cualquier acción legal a la que haya lugar.&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Para no infringir las anteriores restricciones, vale la pena tener en
                            cuenta que cualquier solución de gestión de derechos digitales que se
                            proporciona con el contenido de <strong>SOCCER MARKETING</strong> es una
                            parte inseparable del mismo, y no podrá eludirse, salvo en la medida
                            permitida por la ley aplicable. Si usted está en posesión, control y/o uso
                            del contenido de <strong>SOCCER MARKETING</strong>, es su responsabilidad
                            no perder, destruir ni dañar dicho contenido, comercializar, reproducir o
                            cualquier otra actuación que se asemeje, sin previa autorización de La
                            Sociedad.
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <div
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                        }}
                    >
                        <ol
                            style={{ marginBottom: "0cm", listStyleType: "undefined", marginLeft: 8 }}
                        >
                            <li
                                style={{
                                    margin: "0cm",
                                    fontSize: 16,
                                }}
                            >
                                <strong>
                                    <span style={{ color: "white" }}>
                                        INFORMACIÓN AL CONSUMIDOR
                                    </span>
                                </strong>
                            </li>
                        </ol>
                    </div>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Se deja constancia que&nbsp;
                        </span>
                        <span style={{}}>
                            La Sociedad
                            <strong>
                                <span style={{ color: "white" }}>&nbsp;</span>
                            </strong>
                            <span style={{ color: "white" }}>
                                suministró a los usuarios la información en castellano, clara, veraz,
                                suficiente, oportuna, verificable, comprensible, precisa e idónea sobre
                                los servicios prestados. Así mismo,<strong>&nbsp;</strong>suministró la
                                siguiente información mediante los términos y condiciones:
                            </span>
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <ol start={1} style={{ listStyleType: "lower-alpha", fontSize: 16, marginLeft: 26 }}>
                        <li>
                            <span style={{ color: "white" }}>
                                Las instrucciones para el correcto uso de la plataforma.
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Las especificaciones de los servicios prestados a través de la
                                plataforma.&nbsp;
                            </span>
                        </li>
                        <li>
                            <span style={{ color: "white" }}>
                                Los valores correspondientes a los planes de vinculación.
                            </span>
                        </li>
                    </ol>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Puede consultar nuestra Política de Privacidad y Condiciones de Uso:&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                Canales habilitados para el ejercicio de los derechos
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                &nbsp;
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                SOCCER MARKETING
                            </span>
                        </strong>
                        <span style={{ color: "white" }}>
                            &nbsp;tiene habilitados los siguientes canales para que los titulares
                            ejerzan su derecho a la protección de sus datos personales:
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <table
                        style={{
                            width: "4.4e+2pt",
                            marginLeft: "5.6pt",
                            borderCollapse: "collapse",
                            border: "none"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        width: "154.2pt",
                                        border: "1pt solid white",
                                        padding: "0cm 5.4pt",
                                        height: "14.85pt",
                                        verticalAlign: "top"
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: "0cm",
                                            fontSize: 16,
                                            textAlign: "justify",
                                            border: "none"
                                        }}
                                    >
                                        <span style={{ color: "white" }}>
                                            Página Web
                                        </span>
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "287.55pt",
                                        borderTop: "1pt solid white",
                                        borderRight: "1pt solid white",
                                        borderBottom: "1pt solid white",
                                        borderImage: "initial",
                                        borderLeft: "none",
                                        padding: "0cm 5.4pt",
                                        height: "14.85pt",
                                        verticalAlign: "top"
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: "0cm",
                                            fontSize: 16,
                                            textAlign: "justify",
                                            border: "none"
                                        }}
                                    >
                                        <a href="https://soccermarketing.co/" style={{
                                            color: "white"
                                        }}>
                                            <span style={{}}>
                                                https://soccermarketing.co/
                                            </span>
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "154.2pt",
                                        borderRight: "1pt solid white",
                                        borderBottom: "1pt solid white",
                                        borderLeft: "1pt solid white",
                                        borderImage: "initial",
                                        borderTop: "none",
                                        padding: "0cm 5.4pt",
                                        height: "13.05pt",
                                        verticalAlign: "top"
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: "0cm",
                                            fontSize: 16,
                                            textAlign: "justify",
                                            border: "none"
                                        }}
                                    >
                                        <span style={{ color: "white" }}>
                                            Correo electrónico
                                        </span>
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "287.55pt",
                                        borderTop: "none",
                                        borderLeft: "none",
                                        borderBottom: "1pt solid white",
                                        borderRight: "1pt solid white",
                                        padding: "0cm 5.4pt",
                                        height: "13.05pt",
                                        verticalAlign: "top"
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: "0cm",
                                            fontSize: 16,
                                            textAlign: "justify",
                                            border: "none"
                                        }}
                                    >
                                        <a href="mailto:contacto@soccermarketing.com">
                                            <span style={{
                                                color: "white"
                                            }}>
                                                contacto@soccermarketing.com
                                            </span>
                                        </a>
                                        <span style={{ color: "white" }}>
                                            &nbsp;
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Por favor tenga en cuenta que estos son los únicos canales que{" "}
                            <strong>SOCCER MARKETING</strong> tiene habilitados para las consultas y
                            reclamos por protección de datos personales.
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            lineHeight: "115%",
                        }}
                    >
                        <strong>
                            <span style={{ color: "white" }}>
                                SB DEVELOPERS S.A.S.
                            </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            lineHeight: "115%",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Calle 145 No. 12 86 Of 101&nbsp;
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            lineHeight: "115%",
                        }}
                    >
                        <span style={{ color: "white" }}>
                            Bogotá, Colombia
                        </span>
                    </p>
                    <p
                        style={{
                            margin: "0cm",
                            fontSize: 16,
                            textAlign: "justify",
                            border: "none"
                        }}
                    >
                        <span style={{ color: "white" }}>
                            &nbsp;
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Politicas