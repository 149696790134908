import React from 'react';
import BotonVerde from './BotonVerde';

const Plan = (props) =>{
    let k = 0;
    return(
        <div className={`cld${props.grande?' planMasGrande':''}`}>
            <div className="plan">
                <div className="tituloPlan">{props.titulo}</div>
                <div className={`imagenPlanContainer${props.caracteristicas.length === 0?' imagenPlanGrande':''}`}><img src={props.imagen} alt="perfil"/></div>
                <div className="caracteristicasPlan">
                    {props.caracteristicas.map((c)=>{
                        k+=1
                        if(c==='')return<br key={`cr${k}`}/>
                        return <div key={`cr${k}`} className="caracteristica"><span className="check">✔</span><span>{c}</span></div>
                    })}
                </div>
                <div className="botonPlan"><BotonVerde ruta="/contacto" texto="ELEGIR" tipo={2}/></div>
            </div>
        </div>
    )
}

export default Plan