import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import '../../styles/admin.scss'
import './permiso.sass'
import { authentication, firestore } from "../../Firebase.js"
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { signOut, signInAnonymously } from 'firebase/auth'
import sm from '../../assets/ic_sm_horizontal.png'
import Loadign from '../general/Loading'
import DialogoUnBoton from '../general/DialogoUnBoton'

const Permiso = ({ match, history }) => {

    const [identificacion, setIdentificacion] = useState('')
    const [codigo, setCodigo] = useState('')
    const [jugador, setJugador] = useState(null)
    const [dialogo, setDialogo] = useState(null)
    const [cargando, setCargando] = useState(true)

    const cerrarDialogo = () => setDialogo(null)
    const noPermitido = () => history.push('/')

    useEffect(() => {
        if (!authentication.currentUser)
            signInAnonymously(authentication).then((user) => {
                setCargando(false)
            }).catch((e) => {
                console.log(e)
                setDialogo({
                    titulo: 'Error',
                    descripcion: 'Actualmente presentamos problemas, intentelo mas tarde.',
                    boton: 'Salir',
                    funcion: noPermitido
                })
            })

        return () => {
            signOut(authentication)
        }
        // eslint-disable-next-line
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        setCargando(true)
        const qRepresentantes = query(collection(firestore, `jugadores/${match.params.id}/representantes`), where('documento', '==', identificacion))
        getDocs(qRepresentantes).then((consulta) => {
            if (consulta.size === 0) {
                setDialogo({
                    titulo: 'Documento Incorrecto',
                    descripcion: 'El número de documento que ha ingresado no es valido para este jugador, inténtelo nuevamente.',
                    boton: 'Vale',
                    funcion: cerrarDialogo
                })
                setCargando(false)
            }
            else {
                consulta.forEach((documento) => {
                    if (codigo === documento.data().codigo) {
                        getDoc(doc(firestore, `jugadores`, match.params.id)).then((doc) => {
                            if (doc.data().estadoPermiso === 1)
                                setJugador({
                                    ...doc.data(),
                                    uidDocumento: doc.id
                                })
                            else {
                                setDialogo({
                                    titulo: 'Jugador Verificado',
                                    descripcion: 'Este jugador ya tiene el permiso para ser parte de nuestra plataforma.',
                                    boton: 'Vale',
                                    funcion: noPermitido
                                })
                            }
                            setCargando(false)
                        }).catch((e) => {
                            console.log(e)
                            setCargando(false)
                        }).catch((e) => {
                            console.log(e)
                            setCargando(false)
                        })
                    }
                    else {
                        setDialogo({
                            titulo: 'Código Incorrecto',
                            descripcion: 'El código que ha ingresado es incorrecto, inténtelo nuevamente.',
                            boton: 'Vale',
                            funcion: cerrarDialogo
                        })
                        setCargando(false)
                    }
                })
            }
        }).catch((e) => {
            console.log(e)
            setDialogo({
                titulo: 'Error',
                descripcion: 'Actualmente presentamos problemas, intentelo mas tarde.',
                boton: 'Salir',
                funcion: noPermitido
            })
        })
    }

    const darPermiso = () => {
        setCargando(true)
        updateDoc(doc(firestore, `jugadores`, match.params.id), {
            estadoPermiso: 2
        }).then((doc) => {
            setDialogo({
                titulo: '¡Cuenta Registrada!',
                descripcion: '¡Gracias!',
                boton: 'Salir',
                funcion: noPermitido
            })
            setCargando(false)
        }).catch((e) => {
            console.log(e)
            setDialogo({
                titulo: 'Error',
                descripcion: 'Actualmente presentamos problemas, intentelo mas tarde.',
                boton: 'Salir',
                funcion: noPermitido
            })
            setCargando(true)
        })
    }

    console.log(dialogo)

    return (
        <div className="contenedor-permiso">
            {dialogo && <DialogoUnBoton
                titulo={dialogo.titulo}
                descripcion={dialogo.descripcion}
                boton={dialogo.boton}
                click={dialogo.funcion}
            />}
            {cargando && <Loadign />}
            <div className="fondo fondo-permiso">
                <div className="topImagen">
                    <Link to="/" className="urlImagen"><img src={sm} alt="logo" /></Link>
                </div>
                {!jugador ?
                    <form className="loginContainer formulario-permiso" onSubmit={handleSubmit}>
                        <h2>REGISTRO CUENTA MENOR DE EDAD</h2>
                        <br />
                        <div className="contenedorCampo ancho4">
                            <div>Número de documento de identidad del representante</div>
                            <input type="text" name="identificacion" required value={identificacion} onChange={(e) => { setIdentificacion(e.target.value) }} />
                        </div>
                        <div className="contenedorCampo ancho4">
                            <div>Código de confirmación:</div>
                            <input type="text" name="codigo" required value={codigo} onChange={(e) => { setCodigo(e.target.value) }} />
                        </div>
                        <div className="contenedorMuchoPadding">
                            <button className="botonVerde muchoPadding btnLogin" type="submit">Enviar</button>
                        </div>
                        <br /><br />
                    </form>
                    :
                    <div className="loginContainer formulario-permiso">
                        <h2>REGISTRO CUENTA MENOR DE EDAD</h2>
                        <br />
                        <div className="contenedorCampo ancho4">
                            <div>Nombre del jugador</div>
                            <div className="nombre-jugador">{(`${jugador.nombre} ${jugador.apellido}`).toUpperCase()}</div>
                        </div>
                        <div className="contenedorCampo ancho4">
                            <div>Número de documento de identidad del jugador</div>
                            <div className="nombre-jugador">{jugador.numIdentificacion}</div>
                        </div>
                        <div className="contenedorMuchoPadding">
                            <button className="botonVerde muchoPadding btnLogin" onClick={darPermiso}>Dar Permiso</button>
                        </div>
                        <br /><br />
                    </div>}
            </div>
        </div>
    )
}

export default withRouter(Permiso)