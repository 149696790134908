import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Inicio from './screens/inicio/Inicio';
import Politicas from './screens/privacidadPoliticas/Politicas';
import TerminosCondiciones from './screens/privacidadPoliticas/TerminosCondiciones';
import TerminosClub from './screens/privacidadPoliticas/TerminosClub';
import TerminosMenor from './screens/privacidadPoliticas/TerminosMenor';
import EULA from './screens/privacidadPoliticas/EULA';
import Contacto from './screens/contacto/Contacto';
import Login from './screens/admin/Login';
import Admin from './screens/admin/Admin';
import Permiso from './screens/permiso/Permiso';

function App() {
  return (
    <BrowserRouter>
      <Route exact path='/' component={Inicio} />
      <Route exact path='/terms-conditions' component={TerminosCondiciones} />
      <Route exact path='/terms-club' component={TerminosClub} />
      <Route exact path='/terms-child' component={TerminosMenor} />
      <Route exact path='/eula' component={EULA} />
      <Route exact path='/privacy' component={Politicas} />
      <Route exact path='/contacto' component={Contacto} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/administrador' component={Admin} />
      <Route exact path='/permisos/:id' component={Permiso} />
    </BrowserRouter>
  );
}

export default App;
