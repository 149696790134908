import React, { useEffect } from 'react';
import '../../styles/politicas.scss'
import sm from '../../assets/ic_sm_horizontal.png'
import { Link } from 'react-router-dom'

const TerminosClub = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="fondoPoliticas">
            <div className="topImagen">
                <Link to="/" className="urlImagen"><img src={sm} alt="logo" /></Link>
            </div>
            <div className="topPoliticas">TÉRMINOS Y CONDICIONES CLUBES</div>
            <div className='archivo'>
                <div className='contenedor-archivo'>
                    <h1>
                        TÉRMINOS Y CONDICIONES PARA LOS CLUBES&nbsp;SOCCER&nbsp;MARKETING
                        <strong>&nbsp;</strong>
                    </h1>
                    <p>
                        Bienvenido a <strong>SOCCER&nbsp;MARKETING</strong>, a La sociedad{" "}
                        <strong>SB DEVELOPERS S.A.S.,&nbsp;</strong>propietaria y legítima titular
                        de todos los derechos derivados de la plataforma y la marca{" "}
                        <strong>SOCCER MARKETING</strong>, quien en adelante se denominará La Sociedad, le complace ofrecerle acceso a la plataforma, la cual prestará los servicios que se describirán en el presente documento, estando sujeto a los siguientes Términos y Condiciones. Se entiende que usted ha expresado su consentimiento, voluntario, exento de vicios, previo e informado, de los Términos y Condiciones al momento de registrarse, acceder, aceptar estos Términos y Condiciones y utilizar la plataforma. Si no está de acuerdo con los Términos y Condiciones que aquí se describen, le sugerimos no hacer click en acepto Términos y Condiciones y no hacer uso de la plataforma.&nbsp;
                    </p>
                    <p>
                        Por favor lea todos los términos y condiciones que aquí se incluyen, ya que La Sociedad se exime de cualquier responsabilidad derivada del desconocimiento del contenido del presente documento.
                    </p>
                    <p>
                        Los presentes términos y condiciones tienen como fin regular la relación comercial que tendrá La Sociedad con los clubes y/o asociaciones, teniendo en cuenta la siguiente información:
                    </p>
                    <p>
                        <br />
                    </p>
                    <h1>CLAVE Y USUARIO DE LA PLATAFORMA</h1>
                    <p>
                        Los Clubes y/o asociaciones de fútbol, podrán ingresar a la plataforma
                        denominada <strong>SOCCERMARKETING&nbsp;</strong>con el usuario y clave que les proporcionará La Sociedad tan pronto hayan llegado a un acuerdo comercial que les permita su ingreso. Esto en vista de que La Sociedad realiza una verificación de la información y veracidad del Club y así evitar que terceros puedan realizar un fraude a los jugadores, agentes y/o técnicos de fútbol.
                    </p>
                    <p>
                        La Sociedad otorgará a la persona encargada el usuario y contraseña para ingresar a la plataforma, exonerándose de cualquier responsabilidad por el mal uso de la cuenta por parte del usuario o de terceros que hayan sido o no autorizados por ellos para el desarrollo el uso de la plataforma en cuestión
                    </p>
                    <p>
                        Tampoco se hará responsable por los fraudes realizados por los usuarios que
                        hayan&nbsp;falsificado la información o no hayan otorgado la información
                        fidedigna y veraz, puesto&nbsp;que ha actuado con la debida diligencia al
                        momento del registro de los usuarios a la&nbsp;plataforma&nbsp;tal y como se
                        establece en los Términos y Condiciones de los Usuarios.
                    </p>
                    <p>
                        <br />
                    </p>
                    <h1>
                        SERVICIOS PRESTADOS A LOS CLUBES<strong>&nbsp;</strong>
                    </h1>
                    <p>
                        La&nbsp;Sociedad&nbsp;entrega&nbsp;de&nbsp;manera&nbsp;gratuita&nbsp;el&nbsp;uso&nbsp;exclusivo&nbsp;de&nbsp;la&nbsp;plataforma&nbsp;denominada
                    </p>
                    <p>
                        <strong>SOCCER MARKETING&nbsp;</strong>con el fin de que puedan analizar y
                        encontrar a los jugadores
                    </p>
                    <p>
                        que más se adecúen con los requerimientos que hayan sido establecidos por los clubes, generando un primer acercamiento con los usuarios.
                    </p>
                    <p>
                        La Sociedad se exonera de cualquier responsabilidad cuando sucedan los siguientes supuestos:
                    </p>
                    <ol>
                        <li>
                            Cuando&nbsp;un&nbsp;usuario&nbsp;declare&nbsp;ser&nbsp;mayor&nbsp;de&nbsp;18&nbsp;años&nbsp;o&nbsp;de&nbsp;la&nbsp;mayoría&nbsp;de&nbsp;edad&nbsp;correspondiente&nbsp;al&nbsp;domicilio&nbsp;del&nbsp;usuario,&nbsp;cuando&nbsp;en&nbsp;realidad&nbsp;es&nbsp;un&nbsp;menor&nbsp;de&nbsp;edad.
                        </li>
                        <li>
                            Cuando&nbsp;el&nbsp;usuario&nbsp;otorgue&nbsp;la&nbsp;información&nbsp;falsa&nbsp;correspondiente&nbsp;a&nbsp;la&nbsp;hoja&nbsp;de&nbsp;vida&nbsp;del&nbsp;usuario,&nbsp;datos
                            personales,&nbsp;a sus videos,&nbsp;fotos,&nbsp;etc.
                        </li>
                    </ol>
                    <p>
                        <br />
                    </p>
                    <h1>
                        INFORMACIÓN OTORGADA<strong>&nbsp;</strong>
                    </h1>
                    <p>Los Clubes tendrán acceso a la siguiente información:</p>
                    <ol>
                        <li>Trayectoria&nbsp;de la carrera profesional del jugador</li>
                        <li>Hoja de vida del jugador</li>
                        <li>Videos e imágenes del jugador</li>
                    </ol>
                    <p>
                        Teniendo en cuenta que los Clubes tendrán el manejo de los datos personales de los usuarios, estos deberán ceñirse a lo establecido en la Ley 1581 de 2012 y el Decreto 1377 de 2013 de la legislación colombiana, para dar el adecuado tratamiento, tal y como se muestra a continuación:
                    </p>
                    <p>&nbsp;</p>
                    <h1>
                        <span style={{ fontSize: 18 }}>
                            a) Obligaciones de los responsables del tratamiento de datos
                        </span>
                    </h1>
                    <ul>
                        <li>
                            Garantizar al usuario el uso efectivo, plena y en todo tiempo de los
                            derechos&nbsp;de hábeas data;
                        </li>
                        <li>
                            Impedir la adulteración de la información, pérdida, consulta, uso o acceso
                            no&nbsp;autorizado o&nbsp;fraudulento;
                        </li>
                        <li>
                            Respetar las condiciones de seguridad y privacidad de la información
                            del&nbsp;usuario;
                        </li>
                        <li>
                            Informar a la autoridad de protección de datos, Superintendencia de
                            Industria&nbsp;y&nbsp;Comercio&nbsp;(SIC),&nbsp;cuando&nbsp;se&nbsp;presenten&nbsp;violaciones&nbsp;a&nbsp;los&nbsp;códigos&nbsp;de&nbsp;seguridad&nbsp;y&nbsp;existan
                            riesgos en la administración de la información de los&nbsp;usuarios.
                        </li>
                        <li>
                            Cumplir&nbsp;las&nbsp;instrucciones&nbsp;y&nbsp;requerimientos&nbsp;que&nbsp;imparta&nbsp;la&nbsp;Superintendencia&nbsp;de&nbsp;Industria&nbsp;y
                            Comercio.
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <h1>
                        <span style={{ fontSize: 18 }}>
                            b) Obligaciones de los encargados del tratamiento de datos
                        </span>
                    </h1>
                    <ul>
                        <li>
                            Garantizar al usuario el uso efectivo, plena y en todo tiempo de los
                            derechos&nbsp;de hábeas data;
                        </li>
                        <li>
                            Impedir la adulteración de la información, pérdida, consulta, uso o acceso
                            no autorizado o fraudulento;
                        </li>
                        <li>
                            Abstenerse de circular información que esté siendo controvertida por el usuario y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio;
                        </li>
                        <li>
                            Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella;
                        </li>
                        <li>
                            Informar a la Superintendencia de Industria y Comercio cuando se
                            presenten&nbsp;violaciones a los códigos de seguridad y existan riesgos en
                            la administración&nbsp;de la información de los Titulares;
                        </li>
                        <li>
                            Cumplir&nbsp;las&nbsp;instrucciones&nbsp;y&nbsp;requerimientos&nbsp;que&nbsp;imparta&nbsp;la&nbsp;Superintendencia&nbsp;de&nbsp;Industria&nbsp;y
                            Comercio.
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <h1>DEFINICIONES</h1>
                    <p>1. "Usuarios" de la siguiente manera:</p>
                    <ul>
                        <li>
                            Las personas naturales que tengan calidad de jugadores entre los 12 y 17
                            años, siempre que sus padres y/o representantes legales hayan registrado
                            y/o autorizado a sus hijos hacer parte de{" "}
                            <strong>SOCCER MARKETING</strong>.
                        </li>
                        <li>
                            Las &nbsp; &nbsp;personas &nbsp; &nbsp;naturales, &nbsp; &nbsp;hombres
                            &nbsp; &nbsp;y &nbsp; &nbsp;mujeres, &nbsp; &nbsp;que &nbsp; &nbsp;quieran
                            &nbsp; &nbsp;debutar profesionalmente y se encuentren entre los 18 y 25
                            años.
                        </li>
                        <li>
                            Las personas naturales, hombres y mujeres que se encuentren entre los 13 y
                            40 años que tienen una carrera deportiva profesional y se encuentran
                            buscando una nueva oportunidad.
                        </li>
                        <li>
                            Las&nbsp;personas&nbsp;jurídicas&nbsp;y/o&nbsp;naturales&nbsp;que&nbsp;actúen&nbsp;en&nbsp;calidad&nbsp;de&nbsp;agentes&nbsp;deportivos.
                        </li>
                        <li>Las personas naturales que tengan calidad de técnicos de fútbol.</li>
                    </ul>
                    <p>
                        2. "Contenido de “<strong>SOCCER&nbsp;MARKETING</strong>" significa la música, videos, imágenes, texto, datos personales y otros materiales que usted puede obtener o a los cuales puede acceder en forma digital desde la plataforma, cuyo contenido es propiedad de La Sociedad o de terceros licenciadores.
                    </p>
                    <p>
                        3. "Registro de validación" significa la información que usted proporciona a La Sociedad posterior al registro de los usuarios en la plataforma.
                    </p>
                    <p>&nbsp;</p>
                    <h1>
                        PREGUNTAS<strong>&nbsp;</strong>
                    </h1>
                    <p>
                        Si tiene alguna pregunta o comentario sobre estos Términos y Condiciones, no
                        dude en contactarnos mediante el siguiente correo{" "}
                        <a href="mailto:contacto@soccermarketing.com">
                            contacto@soccermarketing.com
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <h1>
                        TÉRMINOS Y CONDICIONES GENERALES<strong>&nbsp;</strong>
                    </h1>
                    <p>
                        Los presentes Términos y Condiciones específicos se regirán de conformidad con lo establecido en los Términos y Condiciones de generales de{" "}
                        <strong>SOCCER MARKETING</strong>.
                    </p>
                    <p>
                        <strong>SB DEVELOPERS S.A.S.&nbsp;</strong>Calle 145 No. 12 86 Of 101
                        Bogotá, Colombia
                    </p>
                    <p>
                        Se reserva cualquier derecho que no esté expresamente otorgado en el
                        presente documento.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TerminosClub