import React from 'react';
import BotonVerde from './BotonVerde';

const Perfil = (props)=>{
    return (
        <div className="perfil">
            <div className="tituloPerfil">{props.titulo}</div>
            <div className="imagenPerfilContainer"><img src={props.imagen} alt="perfil"/></div>
            <p>{props.texto}</p>
            <div className="botonPerfil"><BotonVerde ruta="/contacto" texto="IR" tipo={2}/></div>
        </div>
    )
}

export default Perfil