import React from 'react';
import '../../styles/dialogo.scss'

const LoadinSeccion = ()=>{
    return(
        <div className="loginSeccion">
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default LoadinSeccion