import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'

import reducer from './reducers/index'
import reduxThunk from 'redux-thunk'

const store = createStore(
	reducer, //todos los reducer
    {}, //Estado Inicial
    applyMiddleware(reduxThunk)
)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    
    , document.getElementById('root'));

serviceWorker.unregister();
