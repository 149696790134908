import React, { Component } from 'react'
import { authentication, firestore } from '../../Firebase'
import { doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import logo from '../../assets/ic_sm_horizontal.png'
import { Redirect } from 'react-router-dom'
import Loading from '../general/Loading'
import '../../styles/admin.scss'

export default class Login extends Component {

    state = {
        cargando: false,
        auth: false,
        user: '',
        pass: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            cargando: true
        })
        signInWithEmailAndPassword(authentication, this.state.user, this.state.pass)
            .then((u) => {
                if (u != null) {
                    const docRef = doc(firestore, "usuarios", u.user.uid);
                    getDoc(docRef).then((d) => {
                        let datos = d.data()
                        if (datos.tipoUsuario === 4) {
                            this.setState({
                                auth: true,
                                cargando: false
                            })
                        }
                        else {
                            alert('El usuario ingresado no tiene permiso para continuar.')
                            signOut(authentication)
                            this.setState({
                                auth: false,
                                cargando: false
                            })
                        }
                    })
                }
                else {
                    alert('Usuario o contraseña incorrectos')
                    this.setState({
                        auth: false,
                        cargando: false
                    })
                }
            }).catch((e) => {
                alert('Usuario o contraseña incorrectos')
                console.log(e)
                this.setState({
                    auth: false,
                    cargando: false
                })
            })
    }

    render() {

        if (this.state.auth)
            return <Redirect to="/administrador" />

        return (
            <div>
                {this.state.cargando ? <Loading /> : ''}
                <form className="loginContainer" onSubmit={this.handleSubmit}>
                    <img src={logo} alt="logo" />
                    <div className="bandaVerde"><span /><div>LOGIN ADMINISTRADOR</div><span /></div>
                    <br />
                    <div className="contenedorCampo ancho4">
                        <div>Correo:</div>
                        <input type="text" name="user" required onChange={this.handleChange} value={this.state.user} />
                    </div>
                    <div className="contenedorCampo ancho4">
                        <div>Contraseña:</div>
                        <input type="password" name="pass" required onChange={this.handleChange} value={this.state.pass} />
                    </div>
                    <div className="contenedorMuchoPadding">
                        <button className="botonVerde muchoPadding btnLogin" type="submit">Iniciar Sesión</button>
                    </div>
                </form>
            </div>
        )
    }
}
