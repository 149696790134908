import React, { Component } from 'react'
import '../../styles/contacto.scss'
import logo from '../../assets/ic_sm_horizontal.png'
import logoR from '../../assets/ic_sm2.png'
import icSelect from '../../assets/ic_select.png'
import { Link } from 'react-router-dom'
import Loadign from '../general/Loading'
import DialogoAviso from './DialogoAviso'
import { getFunctions } from 'firebase/functions'
import firebase from '../../Firebase'


const functions = getFunctions(firebase);
// functions.useFunctionsEmulator("http://localhost:5001")
export default class Contacto extends Component {

    state = {
        cargando: true,
        dialogo: false,
        tipoPerfil: '',
        pais: '',
        ciudad: '',
        correo: '',
        mensaje: '',
        nombre: ''
    }

    componentDidMount() {
        firebase.auth().signInAnonymously().then((user) => {
            this.setState({
                cargando: false
            })
        }).catch((e) => {
            console.log(e)
            this.setState({
                cargando: false,
                dialogo: true
            })
        })
    }

    componentWillUnmount() {
        firebase.auth().signOut()
    }

    cerrarDialogo = () => {
        this.setState({
            dialogo: false,
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        try {
            let callable = functions.httpsCallable('enviarCorreoApp')

            await callable({
                tipoPerfil: this.state.tipoPerfil,
                pais: this.state.pais,
                ciudad: this.state.ciudad,
                correo: this.state.correo,
                nombre: this.state.nombre,
                mensaje: this.state.mensaje,
                tipo: 3
            })
            this.setState({
                dialogo: true,
                tipoPerfil: '',
                pais: '',
                ciudad: '',
                correo: '',
                mensaje: '',
                cargando: false
            })
        } catch (error) {
            console.log(error)
            this.setState({
                cargando: false
            })
        }
    }

    render() {
        return (
            <div className="fondoContacto">
                {this.state.dialogo ? <DialogoAviso cerrar={this.cerrarDialogo} /> : ''}
                {this.state.cargando ? <Loadign /> : ''}
                <div className="contenedorOpcionesContacto">
                    <Link className="opcionTopContacto" to="/">
                        <img src={window.innerWidth <= 800 ? logoR : logo} alt="logo" />
                    </Link>
                </div>
                <form className="contenedorFormulario" onSubmit={this.handleSubmit}>
                    <div className="contenedorCampo ancho3">
                        <div>Tipo de Perfil<span>*</span></div>
                        <span className="contenedorSelect">
                            <img src={icSelect} alt="bajar_select" />
                            <select type="select" name="tipoPerfil" required onChange={(e) => this.handleChange(e)}>
                                <option value=""></option><option value="Jugador">Jugador</option>
                                <option value="Técnico">Técnico</option>
                                <option value="Empresario">Empresario</option>
                                <option value="Club">Club</option>
                            </select>
                        </span>
                    </div>
                    <div className="contenedorCampo ancho3">
                        <div>País/Región<span>*</span></div>
                        <input type="text" name="pais" required onChange={(e) => this.handleChange(e)} value={this.state.pais} />
                    </div>
                    <div className="contenedorCampo ancho3">
                        <div>Ciudad<span>*</span></div>
                        <input type="text" name="ciudad" required onChange={(e) => this.handleChange(e)} value={this.state.ciuda} />
                    </div>
                    <div className="contenedorCampo ancho2">
                        <div>Correo<span>*</span></div>
                        <input type="email" name="correo" required onChange={(e) => this.handleChange(e)} value={this.state.correo} />
                    </div>
                    <div className="contenedorCampo ancho2">
                        <div>Nombre<span>*</span></div>
                        <input type="text" name="nombre" required onChange={(e) => this.handleChange(e)} value={this.state.nombre} />
                    </div>
                    <div className="contenedorCampo ancho1">
                        <div>Mensaje<span>*</span></div>
                        <textarea name="mensaje" required onChange={(e) => this.handleChange(e)} value={this.state.mensaje} />
                    </div>
                    <div className="contenedorMuchoPadding">
                        <button className="botonVerde muchoPadding" type="submit">ENVIAR</button>
                    </div>
                </form>
            </div>
        )
    }
}
