import React, { Component } from 'react';
import '../../styles/inicio.scss'
import { Link } from 'react-router-dom'
import BotonVerde from './components/BotonVerde';
import Perfil from './components/Perfil';
import sm from '../../assets/ic_sm_horizontal.png'
import sm_movil from '../../assets/ic_sm2.png'
import ic_plan_gratis_jugador from '../../assets/ic_plan_gratis_jugador.png'
import ic_plan_099_jugador from '../../assets/ic_plan_099_jugador.png'
import ic_plan_499_jugador from '../../assets/ic_plan_499_jugador.png'
import ic_plan_999_jugador from '../../assets/ic_plan_999_jugador.png'
import ic_plan_1999_jugador from '../../assets/ic_plan_1999_jugador.png'

import ic_plan_gratis_tecnico from '../../assets/ic_plan_gratis_tecnico.png'
import ic_plan_099_tecnico from '../../assets/ic_plan_099_tecnico.png'
import ic_plan_499_tecnico from '../../assets/ic_plan_499_tecnico.png'
import ic_plan_empresario from '../../assets/ic_plan_empresario.png'
import ic_plan_club from '../../assets/ic_plan_club.png'

import appstore from '../../assets/ic_appstore.png'
import playstore from '../../assets/ic_playstore.png'

import jugador from '../../assets/ic_jugador.png'
import tecnico from '../../assets/ic_tecnico.png'
import empresario from '../../assets/ic_empresario.png'
import club from '../../assets/ic_club.png'

import instagram from '../../assets/ic_instagram.png'
import facebook from '../../assets/ic_facebook.png'
import correo from '../../assets/ic_correo.png'

import Planes from './components/Planes';

class Inicio extends Component {
    render() {

        const accederApp = () => {
            window.open("https://play.google.com/store/apps/details?id=com.soccer.marketing", '_blank');
        }

        return (
            <div>
                <div className="fondo">
                    <div className="contenedorOpciones">
                        <Link className="opcionTop" to="/login">Ingresar como Administrador</Link>
                    </div>
                    <div className="informacionApp">
                        <img src={window.innerWidth > 768 ? sm : sm_movil} alt="logo" />
                        <p><span>SOCCER MARKETING</span> es la red más grande de jugadores, clubes y técnicos de fútbol. En esta App podrás potencializar tu carrera al máximo. La forma más fácil de mostrar tu talento al mundo entero.</p>
                        <BotonVerde texto="Descarga la App" tipo={1} ruta="#" />
                        <div className="imagenesTiendas">
                            <img onClick={accederApp} src={playstore} alt="logo" />
                            <img src={appstore} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className="fondo2">
                    <div className="contenedorUsuarios">
                        <div className="tituloFondoVerde">PERFILES</div>
                        <div className="perfilesContainer">
                            <Perfil
                                titulo="Jugador"
                                imagen={jugador}
                                texto="Muéstrale a todos los equipos del mundo tu talento. La nueva forma de tener tu hoja de vida deportiva. Puedes administrar, editar y compartir tu perfil. Se parte de la red más grande e importante de futbolistas"
                            />
                            <Perfil
                                titulo="Técnico"
                                imagen={tecnico}
                                texto="Muéstrale a todos los equipos del mundo tu experiencia y filosofía de juego. La nueva forma de tener tu hoja de vida deportiva. Puedes administrar, editar y compartir tu perfil. Se parte de la red más grande e importante de futbolistas"
                            />
                            <Perfil
                                titulo="Empresario"
                                imagen={empresario}
                                texto="Ten a la mano tu catalogo de jugadores. La nueva forma de mercadearlos y de mostrarle a los equipos de mundo los jugadores que pueden necesitar en sus plantillas. Puedes administrar y editar tu perfil para brindar mayor confianza de tu trabajo al mundo deportivo. Se parte de la red más grande e importante de futbolistas"
                            />
                            <Perfil
                                titulo="Club"
                                imagen={club}
                                texto="La nueva forma de encontrar el jugador con más talento del mundo para tu equipo. Busca el jugador con las características específicas que estas necesitando, además de compartir con tus colegas los jugadores habilitados para una posible negociación"
                            />
                        </div>
                    </div>
                </div>
                <div className="fondo2 fondoGris">
                    <div className="tituloFondoVerde absoluto">PLANES</div>
                    <Planes titulo="Jugador" planes={[
                        {
                            titulo: "PLAN GRATIS",
                            imagen: ic_plan_gratis_jugador,
                            caracteristicas: [
                                'Hoja de Vida con opción de compartirla',
                                'Tiempo Limitado (3 meses de prueba)'
                            ]
                        },
                        {
                            titulo: "PLAN $0.99",
                            imagen: ic_plan_099_jugador,
                            caracteristicas: [
                                'Hoja de Vida con opción de compartirla', '', ''
                            ]
                        },
                        {
                            titulo: "PLAN $4.99",
                            imagen: ic_plan_499_jugador,
                            caracteristicas: [
                                'Creación de Perfil',
                                'Los clubes de tu País pueden ver tu Perfil',
                                'Compartir tu Hoja de Vida',
                                'Contacto con Clubes'
                            ]
                        },
                        {
                            titulo: "PLAN $9.99",
                            imagen: ic_plan_999_jugador,
                            caracteristicas: [
                                'Creación de Perfil',
                                '5 Países pueden ver tu Perfil',
                                'Compartir tu Hoja de Vida',
                                'Contacto con Clubes', ''
                            ]
                        },
                        {
                            titulo: "PLAN $19.99",
                            imagen: ic_plan_1999_jugador,
                            caracteristicas: [
                                'Creación de Perfil',
                                'Todos los Clubes del mundo pueden encontrarte',
                                'Recomendaciones en la creación del Perfil',
                                'Visibilidad en la busqueda de clubes',
                                'Compartir tu Hoja de Vida en diferentes Idiomas'
                            ]
                        }

                    ]} />
                    <Planes titulo="Técnico" planes={[
                        {
                            titulo: "PLAN GRATIS",
                            imagen: ic_plan_gratis_tecnico,
                            caracteristicas: [
                                'Hoja de Vida con opción de compartirla',
                                'Tiempo Limitado (3 meses de prueba)'
                            ]
                        },
                        {
                            titulo: "PLAN $0.99",
                            imagen: ic_plan_099_tecnico,
                            caracteristicas: [
                                'Hoja de Vida con opción de compartirla', '', ''
                            ]
                        },
                        {
                            titulo: "PLAN $4.99",
                            imagen: ic_plan_499_tecnico,
                            caracteristicas: [
                                'Creación de Perfil',
                                'Los clubes de tu País pueden ver tu Perfil',
                                'Compartir tu Hoja de Vida',
                                'Contacto con Clubes'
                            ]
                        },
                        {
                            titulo: "PLAN $9.99",
                            imagen: ic_plan_999_jugador,
                            caracteristicas: [
                                'Creación de Perfil',
                                '5 Países pueden ver tu Perfil',
                                'Compartir tu Hoja de Vida',
                                'Contacto con Clubes', ''
                            ]
                        },
                        {
                            titulo: "PLAN $19.99",
                            imagen: ic_plan_1999_jugador,
                            caracteristicas: [
                                'Creación de Perfil',
                                'Todos los Clubes del mundo pueden encontrarte',
                                'Recomendaciones en la creación del Perfil',
                                'Visibilidad en la busqueda de clubes',
                                'Compartir tu Hoja de Vida en diferentes Idiomas'
                            ]
                        }

                    ]} />

                    <Planes titulo="Empresario" marginRigth={true} planes={[
                        {
                            titulo: "Empresario $2.99",
                            imagen: ic_plan_empresario,
                            caracteristicas: [
                                'Primer jugador GRATIS',
                                'Contactar Jugadores y Técnicos'
                            ]
                        },
                    ]} />
                    <Planes titulo="Club" planes={[
                        {
                            titulo: "Contactar con Soporte",
                            imagen: ic_plan_club,
                            caracteristicas: []
                        },

                    ]} />
                    <div className="foot">
                        <div className="movilContainer">
                            <Link className="footLink" to="/terms-conditions">Politicas de Privacidad</Link>
                            <span className="footLink">|</span>
                            <span className="footLink">Contacto</span>
                            <span className="footLink">|</span>
                            <Link className="footLink" to="/login">Ingresar comoAdministrador</Link>
                        </div>
                        <div className="emailContainer">
                            <div className="mensajeImg">
                                <img src={correo} alt="logoMensaje" />
                            </div>
                            <a href="mailto:contacto@soccermarketing.com"><span>contacto@soccermarketing.com</span></a>
                        </div>
                        <div className="redesContainer">
                            <div className="redes">
                                <a rel="noopener noreferrer" target='_blank' href="https://www.facebook.com/"><img src={facebook} alt="logoMensaje" /></a>
                            </div>
                            <div className="redes">
                                <a rel="noopener noreferrer" target='_blank' href="https://www.instagram.com/"><img src={instagram} alt="logoMensaje" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="botonesPrivacidad">
                        <Link to="/privacy" className="btnPoliticas">Políticas de Privacidad</Link>
                        <Link to="/terms-conditions" className="btnPoliticas">Términos Y Condiciones</Link>
                        <Link to="/terms-club" className="btnPoliticas">Términos Y Condiciones Clubes</Link>
                        <Link to="/terms-child" className="btnPoliticas">Términos Y Condiciones Menores de Edad</Link>
                        <Link to="/eula" className="btnPoliticas">EULA</Link>
                    </div>
                    <div className="botonesPrivacidad" style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'white',
                        padding: '0.5vw 0',
                        borderTop: '1px solid gray',
                        fontSize: '1.2vw'
                    }}>Albeiro Saenz Producciones</div>
                </div>
            </div>
        )
    }
}

export default Inicio