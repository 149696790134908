import React from 'react';
import {Link} from 'react-router-dom'

const BotonVerde = (props) =>{
    if(props.tipo === 0)
    return (
        <div>
            <Link className="botonVerde" to={props.ruta}>{props.texto}</Link>
        </div>
    )
    if(props.tipo === 1)
        return (
            <div className="botonTop">
                <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.soccer.marketing" className="botonVerde">{props.texto}</a>
            </div>
        )
    if(props.tipo === 2)
        return (
            <div>
                <Link className="botonVerde pocoPadding" to={props.ruta}>{props.texto}</Link>
            </div>
        )
    if(props.tipo === 3)
        return (
            <div className="contenedorMuchoPadding">
                <button className="botonVerde muchoPadding" type="submit">{props.texto}</button>
            </div>
        )

    if(props.tipo === 4)
    return (
        <div className="contenedorMuchoPadding">
            <button className="botonVerde muchoPadding" onClick={props.funcion}>{props.texto}</button>
        </div>
    )

    return (
        <div>
            <Link className="botonVerde" to={props.ruta}>{props.texto}</Link>
        </div>
    )
}

export default BotonVerde