import React from 'react';
import logo from '../../assets/ic_sm2.png'

const DialogoAviso = (props) =>{
    return (
        <div className="dialogo">
            <div className="cerrarDialogo" onClick={()=>props.cerrar()}/>
            <div className="fondoDialogo">
                <img src={logo} alt="logo"/>
                <div className="texto1">¡TU MENSAJE FUE ENVIADO CON ÉXITO!</div>
                <div className="texto2">Pronto nos comunicaremos contigo</div>
                <div className="contenedorMuchoPadding bd">
                    <button className="botonVerde muchoPadding" type="button"  onClick={()=>props.cerrar()}>ENTENDIDO</button>
                </div>
            </div>
        </div>
    )
}

export default DialogoAviso