import { firestore } from '../Firebase'
import { query, onSnapshot, orderBy, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore'

export const traerClubes = () => (dispatch) => {

  dispatch({
    type: 'CARGANDO_CLUBES',
    payload: true
  })

  let onCollectionUpdate = (querySnapshot) => {
    let cl = []
    querySnapshot.forEach((doc) => {
      cl.push(doc.data())
    });

    dispatch({
      type: 'TRAER_CLUBES',
      payload: cl
    })
  }
  const qClubes = query(collection(firestore, 'clubes'), orderBy('nombre'))
  onSnapshot(qClubes, onCollectionUpdate)
}

export const traerChats = () => (dispatch) => {

  dispatch({
    type: 'CARGANDO_CHATS',
    payload: true
  })

  let onCollectionUpdate = (querySnapshot) => {
    let cl = []
    querySnapshot.forEach((doc) => {
      cl.push({ ...doc.data(), id: doc.id })
    });

    dispatch({
      type: 'TRAER_CHATS',
      payload: cl
    })
  }
  const qSoporte = query(collection(firestore, 'soporte'), orderBy('fechaUltimoMensaje', 'desc'))
  onSnapshot(qSoporte, onCollectionUpdate)
}

export const traerReportes = () => (dispatch) => {

  dispatch({
    type: 'CARGANDO_REPORTES',
    payload: true
  })

  let onCollectionUpdate = (querySnapshot) => {
    let cl = []
    querySnapshot.forEach((doc) => {
      cl.push({ ...doc.data(), id: doc.id })
    });

    dispatch({
      type: 'TRAER_REPORTES',
      payload: cl
    })
  }
  const qReportes = query(collection(firestore, 'reportes'), orderBy('fechaUltimoReporte', 'desc'))
  onSnapshot(qReportes, onCollectionUpdate)
}

export const traerChat = (uid) => (dispatch) => {

  dispatch({
    type: 'CARGANDO_CHAT',
    payload: true
  })

  let onCollectionUpdate = (querySnapshot) => {
    let cl = []
    querySnapshot.forEach((doc) => {
      cl.push({ ...doc.data(), id: doc.id })
    });

    dispatch({
      type: 'TRAER_CHAT',
      payload: cl
    })
  }
  const qMensajes = query(collection(firestore, `soporte/${uid}/mensajes`), orderBy('fecha', 'desc'))
  onSnapshot(qMensajes, onCollectionUpdate)
}

export const traerReporte = (uid) => (dispatch) => {

  dispatch({
    type: 'CARGANDO_REPORTE',
    payload: true
  })

  let onCollectionUpdate = (querySnapshot) => {
    let cl = []
    querySnapshot.forEach((doc) => {
      cl.push({ ...doc.data(), id: doc.id })
    });

    dispatch({
      type: 'TRAER_REPORTE',
      payload: cl
    })
  }
  const qReporte = query(collection(firestore, `reportes/${uid}/reporte`), orderBy('fecha', 'desc'))
  onSnapshot(qReporte, onCollectionUpdate)
}

export const eliminarChat = (uid, usuario) => (dispatch) => {
  deleteDoc(doc(firestore, 'soporte', uid)).then((d) => {
    dispatch({
      type: 'ELIMINAR_CHAT',
      payload: null
    })
  });

  updateDoc(doc(firestore, 'usuarios', usuario), {
    chatSoporte: null
  }).then((d) => {
    dispatch({
      type: 'ACTUALIZAR_USUARIO',
      payload: null
    })
  });
}

export const actualizarActivo = async (idUsuario, activo) => {
  updateDoc(doc(firestore, 'usuarios', idUsuario), {
    bloqueado: !activo
  }).then(() => {
    return updateDoc(doc(firestore, 'reportes', idUsuario), {
      desactivado: !activo
    }).then(() => {
      return true
    }).catch(e => {
      return false
    })
  })
}