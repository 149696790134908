import React from 'react'
import club from '../../../assets/ic_club.png'
import LoadinSeccion from '../../general/LoadinSeccion'

const Clubs = (props)=>{

    let k = 0


    return (
        <div className="clubsContainer">
            <div className="bandaAzul">
                <button className="botonCircular" onClick={()=>props.pantalla(0)}>+</button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;Agregar Club</span>
            </div>
            <div className="vistaClubs">
                <div className="tituloGris">Clubes</div>
                <input type="text" name="buscar" onChange={props.filtrar} value={props.valorInput} placeholder="Buscar"/>
                <div className="listaClubs">
                    {props.cargando?
                        <LoadinSeccion />
                    :
                        props.clubes.length!==null?
                        props.clubes.length>0?
                        props.clubes.map((club)=>{
                            k+=1
                            return <div className="club" key={`club${k}`}>
                                <span>{club.nombre}</span>
                                <div className="imagenCLub">
                                    <img src={club.urlFotoPerfil}  alt="club"/>
                                </div>
                            </div>
                        })
                        :
                        <div className="club">
                            <span>Actualmente no hay clubes registrados</span>
                            <div className="imagenCLub">
                                <img src={club}  alt="club"/>
                            </div>
                        </div>
                        :
                        <div className="club">
                            <span>Actualmente no hay clubes registrados</span>
                            <div className="imagenCLub">
                                <img src={club}  alt="club"/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Clubs