import React, { useEffect } from 'react';
import '../../styles/politicas.scss'
import sm from '../../assets/ic_sm_horizontal.png'
import { Link } from 'react-router-dom'

const TerminosMenor = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="fondoPoliticas">
            <div className="topImagen">
                <Link to="/" className="urlImagen"><img src={sm} alt="logo" /></Link>
            </div>
            <div className="topPoliticas">TÉRMINOS Y CONDICIONES MENORES DE EDAD</div>
            <div className='archivo'>
                <div className='contenedor-archivo'>
                    <h1>TÉRMINOS Y CONDICIONES PARA MENORES DE EDAD SOCCER MARKETING</h1>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        Bienvenido a <strong>SOCCER MARKETING</strong>, a La Sociedad{" "}
                        <strong>SB DEVELOPERS S.A.S., </strong>propietaria y legítima titular de
                        todos los derechos derivados de la plataforma y la marca{" "}
                        <strong>SOCCER MARKETING</strong>, quien en adelante se denominará La
                        Sociedad, le complace ofrecerle acceso a la plataforma, la cual prestará los
                        servicios que se describirán en el presente documento, estando sujeto a los
                        siguientes Términos y Condiciones. Se entiende que usted ha expresado su
                        consentimiento, voluntario, exento de vicios, previo e informado, de los
                        Términos y Condiciones al momento de registrarse, acceder, aceptar estos
                        Términos y Condiciones y utilizar la plataforma. Si no está de acuerdo con
                        los Términos y Condiciones que aquí se describen, le sugerimos no hacer
                        click en acepto Términos y Condiciones y no hacer uso de la plataforma.
                    </p>
                    <p>
                        Por favor lea todos los términos y condiciones que aquí se incluyen, ya que
                        La Sociedad se exime de cualquier responsabilidad derivada del
                        desconocimiento del contenido del presente documento.&nbsp;
                    </p>
                    <p>
                        Con el propósito de proteger a los menores de edad y sus respectivos datos
                        personales, los padres y/o representantes legales deberán leer el presente
                        documento antes de continuar con el proceso de validación de datos.
                    </p>
                    <p>&nbsp;</p>
                    <h1>1.&nbsp;&nbsp;&nbsp; ¿QUÉ SE ENTIENDE POR MENOR DE EDAD?</h1>
                    <p>
                        En vista que la plataforma permite como usuarios a menores de edad desde los
                        12 años de edad y de conformidad con la protección mundial con la que
                        cuentan estos sujetos de derechos, La Sociedad se vio en la necesidad de
                        regular la interacción de estos en la plataforma y se regirán por las
                        disposiciones contenidas en este documento.
                    </p>
                    <p>
                        Teniendo en cuenta que la minoría de edad puede variar de acuerdo al
                        territorio de residencia del Usuario, se incluye la siguiente tabla como
                        referencia a las estipulaciones correspondientes por cada país.
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td width={312}>
                                    <p>
                                        <strong>PAÍS</strong>
                                    </p>
                                </td>
                                <td width={312}>
                                    <p>
                                        <strong>RANGO DE MINORÍA DE EDAD</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width={312}>
                                    <p>Albania, Samoa Americana</p>
                                </td>
                                <td width={312}>
                                    <p>Menores de 14 años</p>
                                </td>
                            </tr>
                            <tr>
                                <td width={312}>
                                    <p>Irak, Irán, Indonesia</p>
                                </td>
                                <td width={312}>
                                    <p>Menores de 15 años</p>
                                </td>
                            </tr>
                            <tr>
                                <td width={312}>
                                    <p>Cuba, Kirguistán, Reino Unido Escocia, Turkmenistán, Uzbekistán</p>
                                </td>
                                <td width={312}>
                                    <p>Menores de 16 años</p>
                                </td>
                            </tr>
                            <tr>
                                <td width={312}>
                                    <p>Corea del Norte, Malta, Perú, Tayikistán</p>
                                </td>
                                <td width={312}>
                                    <p>Menores de 17 años</p>
                                </td>
                            </tr>
                            <tr>
                                <td width={312}>
                                    <p>
                                        Alemania, Afganistán, Andorra, Angola, Arabia Saudí, Armenia,
                                        Argentina, Australia, Austria, Azerbaiyán, Bahamas, Barbados,
                                        Bielorrusia, Bolivia, Bosnia y Herzegovina, Brasil, Brunéi,
                                        Bulgaria, Burundi,&nbsp;&nbsp; &nbsp;Bután,&nbsp;&nbsp;
                                        &nbsp;Camboya,&nbsp;&nbsp; &nbsp;Canadá, Alberta,&nbsp;&nbsp; &nbsp;Isla&nbsp;&nbsp; &nbsp;del&nbsp;&nbsp;
                                        &nbsp;Príncipe&nbsp;&nbsp; &nbsp;Eduardo, Manitoba,&nbsp;
                                        Ontario,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        Quebec, Saskatchewan, Chile, China, Colombia, Costa de Marfil, Costa
                                        Rica, Croacia, Dominica, Ecuador, El Salvador, España, Filipinas,
                                        Fiyi, Francia, Gabón, Ghana, Guatemala, Guinea, Guyana, Haití,
                                        India, Indonesia, Islandia, Israel, Italia, Jamaica, Kenia, Laos,
                                        Líbano, Liechtenstein, Macao, Macedonia, Malasia, Malta, Mauricio,
                                        Mauritania, México, Moldavia, Mónaco, Montenegro, Nepal, Nicaragua,
                                        Noruega, Nueva Zelanda, Omán, Panamá, Paraguay, Portugal, Qatar,
                                        Reino Unido Gales, Gibraltar, Guernesey, Inglaterra, Irlanda del
                                        Norte, Jersey, Isla de Man, República Checa, República Dominicana,
                                        Ruanda, Rumania, Rusia, Saint Kitts y Nevis, Senegal, Serbia,
                                        Seychelles, Sudáfrica, Sudán, Suiza, Siria, Tanzania, Trinidad y
                                        Tobago, Túnez, Turquía, Ucrania, Uruguay, Venezuela, Vietnam, Yemen, Yibuti,
                                        Zimbabue
                                    </p>
                                </td>
                                <td width={312}>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p>Menores de 18 años</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td width={312}>
                                    <p>Corea del Sur, Taiwán, Tailandia, Japón</p>
                                </td>
                                <td width={312}>
                                    <p>Menores de 20 años</p>
                                </td>
                            </tr>
                            <tr>
                                <td width={312}>
                                    <p>
                                        Bahréin, Camerún, Chad, Egipto, Estados Unidos, Honduras, Irlanda,
                                        Lesoto, Madagascar, Namibia, Nicaragua, Pakistán, Puerto Rico,
                                        Singapur, Suazilandia y Mozambique
                                    </p>
                                </td>
                                <td width={312}>
                                    <p>Menores de 21 años</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        La determinación del status del usuario como “menor de edad” quedará sujeto
                        a la regulación correspondiente al domicilio principal del menor. En ese
                        sentido, cualquier modificación y/o derogación de las normas que regulen la
                        mayoría y minoría de edad en cada jurisdicción, producirán efectos jurídicos
                        en este documento sin que se requiera de notificación previa por parte de La
                        Sociedad.
                    </p>
                    <p>&nbsp;</p>
                    <h1>2.&nbsp;&nbsp;&nbsp; POLÍTICA DE PROTECCIÓN DE DATOS MENORES DE EDAD</h1>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        De conformidad con las políticas mundiales de protección de datos de los
                        menores de edad, La Sociedad presentará la política implementada para
                        alcanzar este propósito. Es por lo anterior que deberán seguir el siguiente
                        paso a paso:&nbsp;
                    </p>
                    <ol>
                        <li>
                            El usuario deberá registrarse con su correo electrónico, Apple ID o
                            Facebook.
                        </li>
                        <li>
                            Tan pronto se hayan registrado, el usuario deberá diligenciar un
                            formulario como requisito de validación de la información personal del
                            usuario. En caso de ser menor de edad, el usuario será notificado con los
                            presentes términos y condiciones y se les solicitará el nombre, apellido y
                            correo electrónico de cada uno de sus padres y/o representante legal.
                        </li>
                        <li>
                            Una vez se haya diligenciado el formato de verificación de datos, los
                            padres y/o representantes legales serán notificados del registro del menor
                            en la aplicación y de cualquier comunicación recibida y/o enviada por el
                            menor, con el fin de que sus padres tengan conocimiento de las actividades
                            e información otorgada por el menor de edad, dado el caso de que el
                            control de la aplicación se encuentre en cabeza del menor de edad.
                        </li>
                    </ol>
                    <p>&nbsp;</p>
                    <h1>3.&nbsp;&nbsp;&nbsp; EXCEPCIÓN DE RESPONSABILIDAD</h1>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        Con la aceptación de los términos y condiciones para menores de edad, el
                        padre, madre, tutor y/o representante legal del Usuario será el único
                        responsable de todas aquellas actividades y/o comunicaciones que tenga el
                        menor de edad relacionadas con el manejo y utilización de la plataforma. En
                        concordancia con lo anterior, La Sociedad presumirá que cualquier actividad
                        relacionada o derivada del uso de la plataforma{" "}
                        <strong>SOCCER MARKETING </strong>es realizada y/o autorizada por el padre,
                        madre, tutor y/o representante legal del Usuario menor de edad, por lo tanto
                        La Sociedad se exime de cualquier responsabilidad por los daños y perjuicios
                        ocasionados al menor.
                    </p>
                    <p>
                        En caso de que exista alguna reclamación judicial o prejudicial en contra de
                        La Sociedad, el usuario deberá responder por los gastos causados por
                        concepto de representación legal, cuota Litis y cualquier otro costo de
                        administración adicional junto con los daños y perjuicios que hayan sido
                        ocasionados a La Sociedad.
                    </p>
                    <p>&nbsp;</p>
                    <h1>
                        4.&nbsp;&nbsp;&nbsp; AUTORIZACIÓN DE LOS PADRES Y/O REPRESENTANTE LEGAL AL
                        MENOR DE EDAD
                    </h1>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        Los padres y/o representante legal autoriza a su hijo menor de edad a crear
                        un usuario en la plataforma denominada <strong>SOCCER MARKETING </strong>y
                        declaran haber sido los únicos que otorgaron la información personal de los
                        menores de edad, garantizando su tratamiento y veracidad.
                    </p>
                    <p>
                        En el mismo sentido, los padres y/o representantes legales declaran haber
                        sido notificados de que la información personal será compartida con los
                        clubes y/o asociaciones de fútbol con el fin de que estos últimos puedan
                        contactarlos y realizar los estudios pertinentes para hacerlos parte o no de
                        los clubes y asociaciones.&nbsp;
                    </p>
                    <p>
                        Es importante recalcar que los padres y/o representantes legales recibirán
                        una notificación por cada mensaje recibido y enviado por parte del usuario
                        con el fin de que tengan conocimiento de los contactos y comunicaciones
                        cibernéticas que los menores estén recibiendo y enviando.
                    </p>
                    <p>&nbsp;</p>
                    <h1>5.&nbsp;&nbsp;&nbsp; DEFINICIONES</h1>
                    <ol>
                        <li>"Usuarios" de la siguiente manera:</li>
                    </ol>
                    <ul>
                        <li>
                            Las personas naturales que tengan calidad de jugadores entre los 12 y 17
                            años, siempre que sus padres y/o representantes legales hayan registrado
                            y/o autorizado a sus hijos hacer parte de{" "}
                            <strong>SOCCER MARKETING</strong>.
                        </li>
                        <li>
                            Las personas&nbsp;&nbsp;&nbsp; naturales,&nbsp;&nbsp;&nbsp;
                            hombres&nbsp;&nbsp;&nbsp; y&nbsp;&nbsp;&nbsp; mujeres,&nbsp;&nbsp;&nbsp;
                            que&nbsp;&nbsp;&nbsp; quieran&nbsp;&nbsp;&nbsp; debutar profesionalmente y
                            se encuentren entre los 18 y 25 años.
                        </li>
                        <li>
                            Las personas naturales, hombres y mujeres que se encuentren entre los 13 y
                            40 años que tienen una carrera deportiva profesional y se encuentran
                            buscando una nueva oportunidad.
                        </li>
                        <li>
                            Las personas jurídicas y/o naturales que actúen en calidad de agentes
                        </li>
                        <li>Las personas naturales que tengan calidad de técnicos de fútbol.</li>
                    </ul>
                    <ol start={2}>
                        <li>
                            "Contenido de “SOCCER MARKETING" significa la música, videos, imágenes,
                            texto, datos personales y otros materiales que usted puede obtener o a los
                            cuales puede acceder en forma digital desde la plataforma, cuyo contenido
                            es propiedad de La Sociedad o de terceros licenciadores.
                        </li>
                        <li>
                            "Registro de validación" significa la información que usted proporciona a
                            La Sociedad posterior al registro de los usuarios en la plataforma.
                        </li>
                    </ol>
                    <p>&nbsp;</p>
                    <h1>6.&nbsp;&nbsp;&nbsp; PREGUNTAS</h1>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        Si tiene alguna pregunta o comentario sobre estos Términos y Condiciones, no
                        dude en contactarnos mediante el siguiente correo{" "}
                        <a href="mailto:contacto@soccermarketing.com">
                            contacto@soccermarketing.com
                        </a>
                    </p>
                    <p>&nbsp;</p>
                    <h1>7.&nbsp;&nbsp;&nbsp; TÉRMINOS Y CONDICIONES GENERALES</h1>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        Los presentes Términos y Condiciones específicos se regirán de conformidad
                        con lo establecido en los Términos y Condiciones de generales de{" "}
                        <strong>SOCCER MARKETING</strong>.
                    </p>
                    <p>
                        <strong>SB DEVELOPERS S.A.S. </strong>Calle 145 No. 12 86 Of 101 Bogotá,
                        Colombia
                    </p>
                    <p>
                        Se reserva cualquier derecho que no esté expresamente otorgado en el
                        presente documento.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TerminosMenor