const INITIAL_STATE = {
    cargandoClubes: false,
    clubs: [],
    cargandoChats: false,
    chats: [],
    cargandoChat: false,
    chat: [],
    cargandoReportes: false,
    reportes: [],
    cargandoReporte: false,
    reporte: [],
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CARGANDO_CLUBES':
            return { ...state, cargandoClubes: action.payload }
        case 'TRAER_CLUBES':
            return { ...state, clubs: action.payload, cargandoClubes: false }
        case 'CARGANDO_CHATS':
            return { ...state, cargandoChats: action.payload }
        case 'TRAER_CHATS':
            return { ...state, chats: action.payload, cargandoChats: false }
        case 'CARGANDO_CHAT':
            return { ...state, cargandoChat: action.payload }
        case 'TRAER_CHAT':
            return { ...state, chat: action.payload, cargandoChat: false }
        case 'CARGANDO_REPORTES':
            return { ...state, cargandoReportes: action.payload }
        case 'TRAER_REPORTES':
            return { ...state, reportes: action.payload, cargandoReportes: false }
        case 'CARGANDO_REPORTE':
            return { ...state, cargandoReporte: action.payload }
        case 'TRAER_REPORTE':
            return { ...state, reporte: action.payload, cargandoReporte: false }
        default: return { ...state }
    }
}