import React from 'react';
import logo from '../../assets/ic_sm2.png'
import PropTypes from 'prop-types'

const DialogoUnBoton = ({titulo, descripcion, boton, click}) =>{
    return (
        <div className="dialogo">
            <div className="cerrarDialogo" onClick={()=>click()}/>
            <div className="fondoDialogo">
                <img src={logo} alt="logo"/>
                <div className="texto1">{titulo}</div>
                <div className="texto2">{descripcion}</div>
                <div className="contenedorMuchoPadding bd">
                    <button className="botonVerde muchoPadding" type="button"  onClick={()=>click()}>{boton}</button>
                </div>
            </div>
        </div>
    )
}

DialogoUnBoton.propTypes = {
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    boton: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
}


export default DialogoUnBoton