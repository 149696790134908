import React, {useState} from 'react'
import PropTypes from 'prop-types'
import LoadingSeccion from '../../general/LoadinSeccion'
import {actualizarActivo} from '../../../actions/adminActions'

const Reporte = ({ reporte, mensajes, pantalla, cargando }) => {

    const [actualizando, setActualizando] = useState(false)
    const [error, setError] = useState(false)

    const actualizarUsuario = async (idUsuario, activo) => {
        setError(false)
        setActualizando(true)
        let resp = await actualizarActivo(idUsuario, activo)
        setError(!resp)
        setActualizando(resp)
        if(resp){
            pantalla(100)
        }
    }

    return (
        <div className="contenedor-reporte">
            {!reporte || cargando || actualizando ?
                <LoadingSeccion />
                :
                <div className="contenido">
                    <div className="fila-info-reportado">
                        <div className="imagenPerfil">
                            <img src={reporte.urlFotoReportado} alt="" />
                        </div>
                        <div className="columna-info">
                            <h3>{reporte.nombreReportado}</h3>
                            <p>{reporte.correoReportado}</p>
                        </div>
                        <button className="botonCircular btn-cerrar" onClick={()=>pantalla(100)}>x</button>
                    </div>
                    {mensajes && mensajes.map( mensaje => {
                        return <div key={mensaje.idReportador}>
                            <hr className="line" />
                            <div className="columna-reporte">
                                <p><strong>Persona que reportó: </strong>{mensaje.nombreReportador}</p>
                                <p><strong>Razón: </strong>{mensaje.asunto}</p>
                                <p><strong>Mensaje: </strong>{mensaje.mensaje}</p>
                            </div>
                        </div>
                    })}
                    <div className="fila-botones">
                        {error && <p>Hubo un error actualizando el usuario, inténtalo de nuevo</p>}
                        <button onClick={()=>actualizarUsuario(reporte.idReportado, reporte.desactivado)} 
                        className="botonVerde btn-desactivar">{reporte.desactivado? "Activar cuenta" : "Desactivar cuenta"}</button>
                    </div>
                </div>
            }
        </div>
    )
}

Reporte.propTypes = {
    reporte: PropTypes.object,
    mensajes: PropTypes.array
}

export default Reporte
