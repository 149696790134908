import React, { useEffect } from 'react';
import '../../styles/politicas.scss'
import sm from '../../assets/ic_sm_horizontal.png'
import { Link } from 'react-router-dom'

const EULA = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="fondoPoliticas">
      <div className="topImagen">
        <Link to="/" className="urlImagen"><img src={sm} alt="logo" /></Link>
      </div>
      <div className="topPoliticas">EULA</div>
      <div className='archivo'>
        <div className='contenedor-archivo'>
          <p>
            Este es un acuerdo legal entre usted y{" "}
            <strong>SB DEVELOPERS S.A.S. </strong>y rige el uso de la aplicación{" "}
            <strong>SOCCER MARKETING </strong>que incluye software de aplicación móvil y
            puede incluir soportes, materiales impresos y documentación electrónica o
            "en línea". Al descargar la aplicación o instalarla, copiarla o en modo
            alguno utilizarla, usted acepta los términos de este{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL</strong>. Si no está de acuerdo
            con todos los términos y condiciones de este{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL</strong>, no abra, descargue,
            instale, copie ni utilice la aplicación en modo alguno.
          </p>
          <p>&nbsp;</p>
          <h1>Alcance de la licencia:</h1>
          <p>
            Se le concede a usted una licencia, no exclusiva, intransferible y no
            sub-licenciable, para descargar, entrar, acceder y utilizar la plataforma
            desde cualquier Producto de la marca <strong>Apple </strong>que posea o
            controle, según lo permita las Reglas de uso establecidas en los Términos y
            condiciones de los Servicios multimedia de <strong>Apple </strong>y conforme
            a lo establecido en los <strong>TÉRMINOS Y CONDICIONES DE USO </strong>y el
            plan que haya sido escogido por usted. En ese sentido, todos los derechos
            que <strong>no </strong>se le conceden expresamente a usted en este{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL</strong>, están reservados para
            La <strong>SB DEVELOPERS S.A.S</strong>.&nbsp;
          </p>
          <p>
            La licencia aquí otorgada, es para su uso personal, razón por la cual se
            considerará vulnerado el derecho cuando personas ajenas a usted hagan uso de
            las facultades que la plataforma les otorga.
          </p>
          <p>
            Así mismo, usted no tiene derecho para copiar o reproducir parcial o
            totalmente el contenido y funcionamiento de la plataforma, ni los videos y
            contenido multimedia que haya sido subido por cualquiera de los otros
            usuarios de la plataforma, ya que se encuentran limitados por lo establecido
            en las leyes y tratados internacionales, correspondientes al derecho de
            autor, propiedad intelectual y cualquier otra regulación que sea necesaria
            para la aplicación del presente{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL</strong>.
          </p>
          <p>&nbsp;</p>
          <h1>Consentimiento para el uso de datos:</h1>
          <p>
            Como consecuencia del acceso y utilización de la plataforma, usted autoriza
            expresamente a <strong>SB DEVELOPERS S.A.S</strong>. a hacer uso de su
            información personal, tales como correo electrónico, cuenta bancaria, número
            de identificación (cédula de ciudadanía, extranjería, número de pasaporte,
            tarjeta de identidad), nombre, apellido, hoja de vida identificando su
            trayectoria profesional, con el fin de que los clubes vinculados a la
            plataforma puedan tener de primera mano la información más importante para
            tomar la decisión que más se adecúe a su expectativas.
          </p>
          <p>
            Lo anterior con el propósito de que <strong>SB DEVELOPERS S.A.S</strong>.
            pueda garantizar el correcto manejo a la información personal de los menores
            de edad, y a su vez entregar la información completa a los clubes y servir
            como entre los jugadores, agentes, técnicos y los clubes y/o asociaciones a
            las que haya lugar.
          </p>
          <p>&nbsp;</p>
          <h1>Terminación:</h1>
          <p>
            El <strong>ACUERDO DE LICENCIA DE USUARIO FINAL </strong>se mantendrá
            vigente hasta que <strong>SB DEVELOPERS S.A.S</strong>. o usted lo den por
            terminado y/o cuando por la ocurrencia de alguna de las
          </p>
          <p>
            causales de terminación establecida en los{" "}
            <strong>TÉRMINOS Y CONDICIONES DE USO, SB DEVELOPERS S.A.S</strong>. lo de
            por terminado.
          </p>
          <p>
            Una vez terminada esta relación jurídica, usted debe dejar de utilizar por
            completo la plataforma y eximir de cualquier responsabilidad a{" "}
            <strong>SB DEVELOPERS S.A.S</strong>.
          </p>
          <p>&nbsp;</p>
          <h1>Derechos de propiedad intelectual:</h1>
          <p>
            La aplicación <strong>SOCCER MARKETING </strong>propiedad de{" "}
            <strong>SB DEVELOPERS S.A.S</strong>. es un software protegido por la
            regulación en el derecho de autor y propiedad intelectual, y por lo tanto,{" "}
            <strong>SB DEVELOPERS S.A.S</strong>. es el titular de{" "}
            <strong>SOCCER MARKETING </strong>en cada una de sus partes, diseños, y
            alcances. Por lo anterior, la aplicación <strong>SOCCER MARKETING </strong>
            solo se podrá utilizar para fines acordados y aceptados por usted en los{" "}
            <strong>TÉRMINOS Y CONDICIONES DE USO </strong>dentro de los que incluyen,
            sin limitarse a estos:
          </p>
          <ul>
            <li>
              Exceder las limitaciones de conformidad con los{" "}
              <strong>TÉRMINOS Y CONDICIONES DE USO</strong>.
            </li>
            <li>
              Permitir a un tercero la redistribución no autorizada de la aplicación{" "}
              <strong>SOCCER MARKETING</strong>, o incluirlo como un servicio u oferta
              de externalización
            </li>
            <li>
              El usuario está obligado a no realizar ninguna acción tendiente a aplicar
              procesos de ingeniería inversa sobre la aplicación{" "}
              <strong>SOCCER MARKETING</strong>, ni sobre ninguno de sus componentes o
              procesos so pena de incurrir en graves violaciones del derecho de
              propiedad intelectual y enfrentar las responsabilidades civiles, penales y
              administrativas a que haya
            </li>
            <li>
              Así mismo, está prohibido copiar o reproducir cualquier porción,
              característica o función la aplicación <strong>SOCCER MARKETING</strong>.
            </li>
            <li>
              Crear un producto o servicio competitivo a partir de la reproducción de
              cualquier porción, característica o función la aplicación{" "}
              <strong>SOCCER MARKETING</strong>.&nbsp;
            </li>
          </ul>
          <p>
            Tanto el diseño (texto y elementos gráficos y/o audiovisuales) como los
            contenidos publicados en la aplicación <strong>SOCCER MARKETING</strong>,
            están protegidos por las normas colombianas e internacionales de derecho de
            autor y propiedad intelectual, por lo tanto, estos y otros derechos sobre
            los materiales son de propiedad de <strong>SB DEVELOPERS S.A.S</strong>.
            Usted podrá ver y descargar la aplicación <strong>SOCCER MARKETING </strong>
            sólo con fines de uso exclusivamente personal.
          </p>
          <p>
            Con el uso de la aplicación <strong>SOCCER MARKETING </strong>usted acepta y
            reconoce que su descarga e interacción en la aplicación{" "}
            <strong>SOCCER MARKETING </strong>no le conceden, ni expresa, ni
            implícitamente, autorización, permiso o licencia de uso de marcas
            comerciales, derechos de autor o de cualquier otro derecho de propiedad
            intelectual de la titularidad de <strong>SB DEVELOPERS S.A.S</strong>..
          </p>
          <p>&nbsp;</p>
          <h1>Servicios externos:</h1>
          <p>
            La Aplicación <strong>SOCCER MARKETING </strong>puede permitir el acceso a
            los servicios y sitios web <strong>SB DEVELOPERS S.A.S</strong>. y/o de
            terceros, en adelante "<strong>SERVICIOS EXTERNOS</strong>".
          </p>
          <p>
            Usted, acepta utilizar los <strong>SERVICIOS EXTERNOS </strong>bajo su
            exclusiva responsabilidad. <strong>SB DEVELOPERS S.A.S</strong>. no es
            responsable de examinar o evaluar el contenido o la precisión de los
          </p>
          <p>
            <strong>SERVICIOS EXTERNOS </strong>de terceros, y no será responsable de
            dichos <strong>SERVICIOS EXTERNOS </strong>de terceros. Los datos mostrados
            por cualquier <strong>SERVICIO EXTERNO</strong>, que incluyen, entre otros,
            información financiera, médica y de ubicación, son solo para fines
            informativos generales y no están garantizados por{" "}
            <strong>SB DEVELOPERS S.A.S</strong>.
          </p>
          <p>
            No utilizará los <strong>SERVICIOS EXTERNOS </strong>de ninguna manera que
            sea incompatible con los términos de este{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL</strong>, los{" "}
            <strong>TÉRMINOS Y</strong>
          </p>
          <p>
            <strong>CONDICIONES DE USO </strong>o que infrinja los derechos de propiedad
            intelectual de <strong>SB DEVELOPERS S.A.S</strong>. o de cualquier tercero.
            Usted acepta no utilizar los <strong>SERVICIOS EXTERNOS </strong>para
            acosar, abusar, acechar, amenazar o difamar a ninguna persona o entidad, de{" "}
            <strong>SB DEVELOPERS S.A.S</strong>. no será responsable de dicho
            uso.&nbsp;
          </p>
          <p>
            Es posible que los <strong>SERVICIOS EXTERNOS </strong>no estén disponibles
            en todos los idiomas o en su país de origen, y es posible que no sean
            apropiados o no estén disponibles para su uso en una ubicación en
            particular. En la medida en que elija utilizar dichos{" "}
            <strong>SERVICIOS EXTERNOS</strong>, usted es el único responsable del
            cumplimiento de las leyes aplicables. <strong>SB DEVELOPERS S.A.S</strong>.
            se reserva el derecho de cambiar, suspender, eliminar, deshabilitar o
            imponer restricciones o límites de acceso a cualquier{" "}
            <strong>SERVICIO EXTERNO </strong>en cualquier momento sin previo aviso ni
            responsabilidad con usted.
          </p>
          <p>&nbsp;</p>
          <h1>Garantías:</h1>
          <p>
            La aplicación <strong>SOCCER MARKETING </strong>y cualquier servicio
            realizado o proporcionado por la aplicación licenciada se proporcionan "tal
            cual" y "según disponibilidad", con todas las fallas y sin garantía de
            ningún tipo, y cualquier garantía de licencia. y condiciones con respecto a
            la aplicación
          </p>
          <p>
            <strong>SB DEVELOPERS S.A.S</strong>. no garantiza que el servicio sea
            compatible con el o los dispositivos destinados para adquirir y usar la
            plataforma, razón por la cual usted es el único responsable de asegurarse de
            que sus sistemas funcionen correctamente con el servicio y acepta
            expresamente que el uso de la aplicación <strong>SOCCER MARKETING </strong>
            es bajo su propio riesgo. en la medida máxima permitida por la ley.
          </p>
          <p>&nbsp;</p>
          <h1>Mantenimiento y soporte:</h1>
          <p>
            Usted autoriza a <strong>SB DEVELOPERS S.A.S</strong>. a actualizar,
            modificar e instalar el software, de manera automática o requerida, entre
            otras razones para abordar cuestiones de seguridad, interoperabilidad y/o
            funcionamiento. Estas actualizaciones, modificaciones y operaciones
            similares pueden realizarse en forma periódica o cuando sea necesario, sin
            previo aviso, por su parte usted acepta y se obliga a instalar las últimas
            actualizaciones o mejoras de la aplicación{" "}
            <strong>SOCCER MARKETING.</strong>
          </p>
          <p>
            El servicio de soporte y las reclamaciones sobre la aplicación{" "}
            <strong>SOCCER MARKETING </strong>se realizarán mediante el correo
            electrónico de <strong>SB DEVELOPERS S.A.S</strong>., el servicio de soporte
            se limitará a: la asistencia con la instalación de la aplicación{" "}
            <strong>SOCCER MARKETING</strong>, su activación, transferencias, mensajes
            de error, fallos o problemas operativos, así mismo, el servicio de soporte
            sobre la aplicación <strong>SOCCER MARKETING </strong>se limita a las
            plataformas y versiones vigentes al momento de la solicitud.
          </p>
          <p>
            Para la solicitud de soporte sobre la aplicación{" "}
            <strong>SOCCER MARKETING</strong>, usted acepta seguir las recomendaciones
            del equipo de soporte de <strong>SB DEVELOPERS S.A.S</strong>. y a
            proporcionar toda la información que el equipo de soporte de{" "}
            <strong>SB DEVELOPERS S.A.S</strong>. solicite razonablemente para atender
            su solicitud.
          </p>
          <p>
            Tal información puede incluir entre otros: su nombre, el de su compañía (de
            ser aplicable) número de ID; tipo y modelo del dispositivo; mensajes de
            error; capturas de pantalla.
          </p>
          <p>&nbsp;</p>
          <h1>Limitación de responsabilidad.</h1>
          <p>
            <strong>SB DEVELOPERS S.A.S</strong>. no asumirá responsabilidad alguna por
            ningún daño o virus en su computadora, celular u otra propiedad como
            consecuencia del acceso, uso, descarga o navegación por la aplicación{" "}
            <strong>SOCCER MARKETING</strong>.&nbsp;
          </p>
          <p>
            En ningún caso, <strong>SB DEVELOPERS S.A.S</strong>. será responsable por
            daños y perjuicios que surjan de y/o en relación con la plataforma y estos
            términos y condiciones, entre otros:
          </p>
          <ol>
            <li>Daños y perjuicios derivados de la pérdida de datos</li>
            <li>Lucro cesante y daño emergente por pérdida del uso de la plataforma</li>
            <li>
              Daños y perjuicios derivados del uso que usted haga del servicio y que
              infrinja estos Términos y Es por esto que bajo ninguna circunstancia La
              Sociedad no será responsable por el uso no autorizado de cualquier
              contenido o cualquier uso del servicio para desarrollar, distribuir o
              utilizar cualquier material que sea difamatorio, calumnioso, injurioso u
              obsceno, que constituya una invasión de cualquier derecho a la privacidad
              o una infracción de cualquier derecho a la publicidad, que viole o
              infrinja los derechos de cualquier tercero o que vulnere cualquier ley o
              regulación extranjera, federal, estatal o local.
            </li>
          </ol>
          <p>&nbsp;</p>
          <h1>Cumplimiento legal:</h1>
          <p>Usted, manifiesta y garantiza que:</p>
          <ol>
            <li>
              no se encuentra en un país sujeto a un embargo por parte del Gobierno de
              UU. o que ha sido designado por este como un país de apoyo terrorista; y
              que
            </li>
            <li>
              no figura en ninguna lista del gobierno de Estados Unidos de partidos
              prohibidos o
            </li>
          </ol>
          <p>
            Usted reconoce y acepta que los licenciatarios de{" "}
            <strong>SB DEVELOPERS S.A.S</strong>. relacionados con la Aplicación son
            terceros beneficiarios del presente{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL</strong>, por lo tanto, los
            licenciatarios de <strong>SB DEVELOPERS S.A.S</strong>. tendrán derecho a
            exigirle el cumplimiento del{" "}
            <strong>ACUERDO DE LICENCIA DE USUARIO FINAL </strong>en calidad de tercero
            beneficiario del presente.
          </p>
          <p>&nbsp;</p>
          <h1>Legislación aplicable</h1>
          <p>
            Estos Términos y Condiciones se regirán de conformidad con las leyes
            colombianas y de la siguiente:
          </p>
          <p>
            Toda controversia o diferencia relativa a estos Términos y Condiciones y las
            que surgieran en temas relacionados directa o indirectamente con derechos de
            autor y/o conexos que surgieran entre las partes, se resolverán por un
            Tribunal de Arbitramento que funcionará en el Centro de Conciliación y
            Arbitraje “Fernando Hinestroza” de la Dirección Nacional de Derechos de
            Autor y toda diferencia que surja en relación con cualquier otra situación
            derivada del presente contrato, que no tenga que ver con temas de derechos
            de autor y/o conexos, se resolverán por el Tribunal de Arbitramento del
            Centro de Conciliación y Arbitraje de la Cámara de Comercio de Bogotá.
          </p>
          <p>
            Cualquiera de los Tribunales se regirá por el procedimiento dispuesto en la
            Ley 1563 del 2012 y a las demás disposiciones legales que los reglamenten,
            adicionen o modifiquen y de acuerdo con las siguientes reglas:
          </p>
          <ol>
            <li>
              El Tribunal estará integrado por un (1) árbitro, si es por mínima o menor
              cuantía, que será nombrado por las partes en conflicto de común acuerdo y
              si no llegare a haber acuerdo, se escogerá por sorteo de las listas de
              árbitros del Centro de Conciliación y Arbitraje competente para el
            </li>
          </ol>
          <ol start={2}>
            <li>
              El Tribunal estará integrado por tres (3) árbitros si es por mayor
              cuantía, los cuales serán nombrados por las partes en conflicto de común
              acuerdo y si no llegare a haber acuerdo, se escogerán por sorteo de las
              listas de árbitros del Centro de Conciliación y Arbitraje competente para
              el
            </li>
          </ol>
          <ol start={3}>
            <li>El Tribunal decidirá en</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            En el evento de que exista algún elemento extranjero en los conflictos
            estipulados en la presente cláusula, en virtud de esta misma cláusula, se
            podrá acudir a arbitraje internacional, según lo dispuesto en el artículo 62
            de la Ley 1563 de 2012.
          </p>
          <p>
            Si el arbitraje es internacional, el Tribunal Arbitral funcionará en el
            Centro de Conciliación y Arbitraje de la Cámara de Comercio de Bogotá se
            regirá por las siguientes reglas:
          </p>
          <p>
            El Tribunal se sujetará al Reglamento de Arbitraje Comercial Internacional
            del Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá.
          </p>
          <ol>
            <li>
              El Tribunal estará integrado por un (1) árbitro, si es por mínima o menor
              cuantía, que será nombrado por las partes en conflicto de común acuerdo y
              si no llegare a haber acuerdo, se escogerá por sorteo de las listas de
              árbitros del Centro de Conciliación y Arbitraje competente para el
            </li>
          </ol>
          <ol start={2}>
            <li>
              El Tribunal estará integrado por tres (3) árbitros si es por mayor
              cuantía, los cuales serán nombrados por las partes en conflicto de común
              acuerdo y si no llegare a haber acuerdo, se escogerán por sorteo de las
              listas de árbitros del Centro de Conciliación y Arbitraje competente para
              el
            </li>
          </ol>
          <ol start={3}>
            <li>El Tribunal decidirá en</li>
          </ol>
          <ol start={4}>
            <li>El Tribunal conocerá en español (Castellano).</li>
          </ol>
          <ol start={5}>
            <li>Las leyes de fondo aplicables serán las de la República de</li>
          </ol>
          <p>&nbsp;</p>
          <h1>Nombre y dirección del desarrollador:</h1>
          <p>
            Si tiene cualquier pregunta, queja o reclamo del respecto a la Aplicación,
            no dude en contactarnos mediante el siguiente correo{" "}
            <a href="mailto:contacto@soccermarketing.com">
              contacto@soccermarketing.com
            </a>
            &nbsp;
          </p>
          <p>
            <strong>SB DEVELOPERS S.A.S. </strong>Calle 145 No. 12 86 Of 101 Bogotá,
            Colombia
          </p>
        </div>
      </div>
    </div>
  )
}

export default EULA