import React from 'react';
import '../../styles/dialogo.scss'

const Loadign = ()=>{
    return(
        <div className="dialogo">
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loadign