import React, { Component } from 'react'
import * as adminActions from '../../actions/adminActions'
import { connect } from 'react-redux'
import logo from '../../assets/ic_sm_horizontal.png'
import { storage, firestore, authentication, functions } from '../../Firebase'
import { onAuthStateChanged, signOut } from "firebase/auth"
import { ref } from "firebase/storage"
import { httpsCallable } from "firebase/functions"
import { addDoc, collection } from "firebase/firestore"
import { Redirect } from 'react-router-dom'
import Loadign from '../general/Loading'
import '../../styles/admin.scss'
import '../../styles/fonts.scss';
import Clubs from './components/Clubs'
import ChatsReportes from './components/ChatsReportes'
import Chat from './components/Chat'
import NuevoClub from './components/NuevoClub'
import club from '../../assets/ic_club.png'
import Reporte from './components/Reporte'

class Admin extends Component {

    state = {
        auth: true,
        cargando: false,
        cargaInicial: false,
        chats: true,
        pantalla: 100,
        clubes: [],
        filtrando: false,
        valorInput: '',
        formularioRegistro: { numeroJugadores: 0, esProfesional: true, pais: '' },
        fotoClub: null,
        chatSeleccionado: null,
        reporteSeleccionado: null,
        textoMensaje: '',
        telefono: false
    }

    componentDidMount() {
        onAuthStateChanged(authentication, (u) => {
            if (u != null) {
                this.setState({
                    auth: true,
                    cargaInicial: false
                })
            }
            else {
                this.setState({
                    auth: false,
                    cargaInicial: false
                })
            }
        })

        this.props.traerClubes()
        this.props.traerChats()
        this.props.traerReportes()
    }

    handleChage = (e) => {

        if (e.target.name === 'foto') {
            this.setState({
                fotoClub: e.target.files[0]
            })
            return
        }

        if (e.target.name === 'mensaje') {
            this.setState({
                textoMensaje: e.target.value
            })
            return
        }

        this.setState({
            formularioRegistro: {
                ...this.state.formularioRegistro,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            cargando: true
        })

        let v = ''
        let pass = '1234567890'
        if (this.state.formularioRegistro.nombre) {
            v = this.state.formularioRegistro.nombre
                .replace("Á", "A").replace("É", "E").replace("Í", "I").replace("Ó", "O").replace("Ú", "U")
                .replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u").replace(' ', '')
            pass = `${v}2020`
        }

        if (v === '' || pass === '1234567890') {
            this.setState({
                cargando: false,
                formularioRegistro: { numeroJugadores: 0, esProfesional: true },
                fotoClub: null
            })
            return
        }

        let rand_code = (chars, lon) => {
            let code = "";
            for (let x = 0; x < lon; x++) {
                let rand = Math.floor(Math.random() * chars.length);
                code += chars.substr(rand, 1);
            }
            return code;
        };

        let caracteres = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ012346789"

        let reference = ref(storage)
        let ruta = ''
        try {
            let st = reference.child(`clubes/${this.state.formularioRegistro.nombre}-${rand_code(caracteres, 4)}.png`)
            await st.put(this.state.fotoClub)
            ruta = await st.getDownloadURL()
            console.log('ya se subió la foto')
        }
        catch (e) {
            console.log('fallo el documeto usuario')
            console.log(e)
            ruta = ''
        }
        let formulario = { ...this.state.formularioRegistro, urlFotoPerfil: ruta }
        let func = httpsCallable(functions, 'registrarClub')
        func({
            correo: formulario.correo,
            nombre: formulario.nombre,
            password: pass,
            formulario: formulario
        }).then((r) => {
            console.log('la function :D')
            console.log(r)

            this.setState({
                pantalla: 100,
                cargando: false,
                formularioRegistro: { numeroJugadores: 0, esProfesional: true },
                fotoClub: null
            })
        })
    }

    enviarMensaje = async (e) => {

        if (this.state.textoMensaje === '') return
        await addDoc(collection(firestore, `soporte/${this.state.chatSeleccionado.id}/mensajes`), {
            esClub: true,
            fecha: new Date(),
            mensaje: this.state.textoMensaje
        })
        this.setState({
            textoMensaje: ''
        })
    }



    filtrarClubes = (e) => {

        if (e.target.value === "") {
            this.setState({
                valorInput: e.target.value,
                filtrando: false
            })
            return
        }

        let clubesFiltrados = []
        let v = e.target.value.toUpperCase()
        v = v.replace("Á", "A").replace("É", "E").replace("Í", "I").replace("Ó", "O").replace("Ú", "U")
        for (let index = 0; index < this.props.clubs.length; index++) {
            const c = this.props.clubs[index];

            let n = c.nombre.toUpperCase()
            n = n.replace("Á", "A").replace("É", "E").replace("Í", "I").replace("Ó", "O").replace("Ú", "U")

            if (n.includes(v)) {
                clubesFiltrados.push(c)
            }
        }

        if (clubesFiltrados.length === 0) {
            clubesFiltrados.push(
                {
                    nombre: 'Sin Resultados',
                    urlFotoPerfil: club,
                })
        }
        this.setState({
            clubes: clubesFiltrados,
            valorInput: e.target.value,
            filtrando: true
        })
    }

    seleccionarChat = (chat) => {
        this.setState({
            pantalla: 150,
            chatSeleccionado: chat
        })
        this.props.traerChat(chat.id)
    }

    seleccionarReporte = (reporte) => {
        this.setState({
            pantalla: 150,
            reporteSeleccionado: reporte
        })
        this.props.traerReporte(reporte.id)
    }

    eliminarChat = (chat) => {
        this.setState({
            pantalla: 100,
            chatSeleccionado: null,
            textoMensaje: ''
        })
        if (chat === null) return
        this.props.eliminarChat(chat.id, chat.idUsuario)
    }

    cambiarPantalla = (p) => {
        this.setState({
            pantalla: p
        })
    }

    render() {

        if (!this.state.auth) {
            return <Redirect to='/login' />
        }

        if (this.state.cargaInicial) {
            return <Loadign />
        }

        return (
            <div className="conetendorAdmin">
                {this.state.cargando ? <Loadign /> : ''}
                <div className="bandaVerde">
                    <img src={logo} alt="logo" />
                    <div>ADMINISTRADOR</div>
                    <button className="cerrarSesion" type="button" onClick={() => signOut(authentication)}>Cerrar Sesión</button>
                </div>
                <div className="conetendorFunciones" style={{ marginLeft: `-${this.state.pantalla}vw` }}>
                    <NuevoClub
                        pantalla={this.cambiarPantalla}
                        cambio={this.handleChage}
                        enviar={this.handleSubmit}
                        foto={this.state.fotoClub}
                        nombre={this.state.formularioRegistro.nombre}
                        correo={this.state.formularioRegistro.correo}
                        pais={this.state.formularioRegistro.pais}
                    />
                    <Clubs
                        cargando={this.props.cargandoClubes}
                        pantalla={this.cambiarPantalla}
                        clubes={this.state.filtrando ? this.state.clubes : this.props.clubs}
                        filtrar={this.filtrarClubes}
                        valorInput={this.state.valorInput}
                    />
                    <div className="columna-chats">
                        <div className="fila-titulo">
                            <button onClick={() => {
                                this.setState({ chats: !this.state.chats })
                                this.cambiarPantalla(100)
                            }} className="botonVerde btn-reportes">{this.state.chats ? "Ver reportes" : "Ver chats"}</button>
                            <h2 className="titulo">{this.state.chats ? "CHAT" : "REPORTES"}</h2>
                            <p className="num-solicitudes">{this.state.chats ? `Nuevas Solicitudes ${this.props.chats ? this.props.chats.length : 0}`
                                : `Nuevos Reportes ${this.props.reportes ? this.props.reportes.length : 0}`}</p>
                        </div>
                        <ChatsReportes
                            seleccionar={this.state.chats ? this.seleccionarChat : this.seleccionarReporte}
                            chats={this.props.chats ?? []}
                            reportes={this.props.reportes ?? []}
                            cargando={this.state.chats ? this.props.cargandoChats : this.props.cargandoReportes}
                            esChat={this.state.chats}
                        />
                    </div>
                    {this.state.chats ? <Chat
                        pantalla={this.cambiarPantalla}
                        mensajes={this.props.chat}
                        chat={this.state.chatSeleccionado}
                        cargando={this.props.cargandoChat}
                        texto={this.state.textoMensaje}
                        cambio={this.handleChage}
                        enviarMensaje={this.enviarMensaje}
                        eliminar={this.eliminarChat}
                        telefono={this.state.telefono}
                        verTelefono={() => this.setState({ telefono: !this.state.telefono })}
                    /> :
                        <Reporte
                            pantalla={this.cambiarPantalla}
                            reporte={this.state.reporteSeleccionado}
                            cargando={this.props.cargandoReporte}
                            mensajes={this.props.reporte} />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (reducers) => {
    return reducers.adminReducer
}

export default connect(mapStateToProps, adminActions)(Admin)