import React from 'react'
import LoadinSeccion from '../../general/LoadinSeccion'
import TextareaAutosize from 'react-textarea-autosize';
import imagen from '../../../assets/ic_jugador.png'

const Chats = (props) => {
    let k=0

    const handleKeyDown = (e)=>{
        if(e.keyCode === 13 && !e.shiftKey){
            e.preventDefault()
            props.enviarMensaje()
        }
    }

    return (
        <div className="chatContainer">
            <div className="informacionChat">
                <div className="chatImagen">
                    <img src={props.chat!=null?props.chat.urlFotoPerfil:imagen}  alt=""/>
                </div>
                <div className="textosInfoChat">
                    <div><b>{props.chat!=null?props.chat.nombre:''}</b></div>
                    <div>
                        {props.chat!=null?props.telefono?
                            <a href={`tel:+57${props.chat.telefono}`}>{props.chat.telefono.replace('+','')}</a>
                            :
                            <a href={`mailto:${props.chat.correo}`}>{props.chat.correo}</a>
                        :''}
                    </div>
                </div>
                <div className="iconosChat">
                    <nav className={props.chat!=null?props.telefono?'icon-mail':'icon-phone':'icon-phone'} onClick={props.verTelefono}/>
                    <nav className="icon-bin" onClick={()=>props.eliminar(props.chat)}/>
                </div>
                <button className="botonCircular" onClick={()=>props.pantalla(100)}>x</button>
            </div>
            <div className="chatMensajes">
                <div className="mensajesContainer">
                    {
                        props.cargando?<LoadinSeccion />:
                        props.mensajes==null?'':
                        props.mensajes.length>0?props.mensajes.map((mensaje)=>{
                            
                            k+=1

                            var startTime = mensaje.fecha.toDate(); 
                            var endTime = new Date();
                            var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
                            var resultInMinutes = Math.round(difference / 60000);

                            let t
                            if(resultInMinutes<60){
                                t = `Hace ${Math.round(resultInMinutes)} Minutos`
                            }
                            else if((resultInMinutes/60)<24){
                                t = `Hace ${Math.round(resultInMinutes/60)} Horas`
                            }
                            else if(((resultInMinutes/60))/24<30){
                                t = `Hace ${Math.round(resultInMinutes/60)} Días`
                            }
                            else if(((resultInMinutes/60))/24>30){
                                t = `${startTime.getDay()}/${startTime.getMonth()}/${startTime.getFullYear()}`
                            }

                            return (
                                <div className={`mensaje ${mensaje.esClub?'mensajeSoporte':'mensajeCliente'}`} key={`mensaje${k}`}>
                                    <span>{mensaje.mensaje}</span>
                                    <div className="fechaMensaje">{t}</div>
                                </div>
                            )
                        }):''
                    }
                </div>
                <div className="enviarMensaje">
                    <TextareaAutosize placeholder='Escribe un mensaje' name="mensaje" onChange={props.cambio} onKeyDown={handleKeyDown} value={props.texto}/>
                <button onClick={props.enviarMensaje}>{`>`}</button>
                </div>
            </div>
        </div>
    )
}
export default Chats