import React from 'react';
import Plan from './Plan';

const Planes = (props) => {
    let k = 0
    return (
        <div className={`ContenedorPlanesUsuarios${props.planes.length === 1 ? ' unPlan' : ''} ${props.marginRigth ? ' marginRigth' : ''}`}>
            <div className="subtituloVerde">{props.titulo}</div>
            <div className="contenedorPlanes">
                {props.planes.map((plan) => {
                    k += 1
                    return <Plan key={`plan${k}`}
                        titulo={plan.titulo}
                        imagen={plan.imagen}
                        caracteristicas={plan.caracteristicas}
                        grande={props.planes.length === 1}
                    />
                })}
            </div>
        </div>
    )
}

export default Planes