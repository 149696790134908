import React from 'react'
import club from '../../../assets/ic_club.png'
import {paises} from './paises'

const NuevoClub = (props) => {
    let k = 0
    return (
        <form className="newClubContainer" onSubmit={props.enviar}>
            <div className="bandaAgregarCLub">
                <div className="vacio" />
                <span>Nuevo Club</span>
                <button type="button" className="botonCircular" onClick={()=>props.pantalla(100)}>x</button>
            </div>
            <div className="datosClubContainer">
                <div className="datosFotoCLub">
                    <div className="fotoNuevoCLub"><img src={props.foto==null?club:URL.createObjectURL(props.foto)} alt="foto club"/></div>
                    <label>
                        <input type="file" name="foto" onChange={props.cambio} accept='image/*' required/>
                        <div>ADJUNTAR FOTO</div>
                    </label>
                    <div className="textoImagenClub">Adjunte una imagen en formato png. o jpg. del logo del club</div>
                </div>
                <div className="camposClub">
                    <div className="contenedorCampo ancho1">
                        <div>Nombre del Club:</div>
                        <input type="text" name="nombre" value={props.nombre} onChange={props.cambio} required/>
                    </div>
                    <div className="contenedorCampo ancho1">
                        <div>Correo del Club:</div>
                        <input type="email" name="correo" value={props.correo} onChange={props.cambio} required/>
                    </div>
                    <div className="contenedorCampo ancho1">
                        <div>País</div>
                        <span className="contenedorSelect">
                            <select type="select" name="pais" value={props.pais} onChange={props.cambio} required>
                                <option value="" disabled></option>
                                {
                                    paises.map((pais)=>{
                                        k+=1
                                        return <option value={pais} key={`pais${k}`}>{pais}</option>
                                    })
                                }
                                <option value={false}>Amateur</option>
                            </select>
                        </span>
                    </div>
                </div>
                <div className="contenedorMuchoPadding botonAgregarClub">
                    <button className="botonVerde muchoPadding" type="submit">Añadir</button>
                </div>
            </div>
        </form>
    )
}

export default NuevoClub